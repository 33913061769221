require('./bootstrap');

import Vue from 'vue';

import VueRouter from 'vue-router';

import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import VueTippy from "vue-tippy";
import "tippy.js/themes/light.css";

Vue.use(VueTippy);

import JwPagination from 'jw-vue-pagination';
Vue.component('jw-pagination', JwPagination);
import routes from './routes'

// STORE
import Vuex from 'vuex';
import modals from './stores/modals.js';
import user from './stores/user.js';
import folders from './stores/folders.js';
import documentTypes from './stores/documentTypes.js';
import stepTypes from './stores/stepTypes.js';
import nextcloud from './stores/nextcloud.js';
import status from './stores/status.js';
import users from './stores/users.js';
import companies from './stores/companies.js';
import contacts from './stores/contacts.js';
import stepStatuses from './stores/stepStatuses.js';
import mailTemplates from './stores/mailTemplates.js';

import Vuelidate from 'vuelidate'

import axios from 'axios'

import { LMap, LTileLayer, LMarker, LWMSTileLayer, LGeoJson, LIcon } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';


import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});


const files = require.context('./', true, /\.vue$/i);
files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

Vue.component("v-select", vSelect);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-wms-tile-layer', LWMSTileLayer);
Vue.component('l-marker', LMarker);
Vue.component('l-geojson', LGeoJson);
Vue.component('l-icon', LIcon);
Vue.use(VueRouter);
Vue.use(Vuex);
Vue.use(Vuelidate);



const router = new VueRouter({
    routes,
    mode: 'history',
});

const store = new Vuex.Store({
    modules: {
        modals,
        user,
        folders,
        documentTypes,
        stepTypes,
        nextcloud,
        status,
        users,
        companies,
        stepStatuses,
        contacts,
        mailTemplates,
    }
});

store.dispatch('getContacts')
axios.get('/api/status').then(r => store.commit('setStatuses', r.data))
axios.get('/api/users').then(r => store.commit('setUsers', r.data))
axios.get('/api/companies').then(r => store.commit('setCompanies', r.data))

store.dispatch('getUser').then(() => {}).catch(() => {
    router.push('login')
})

new Vue({
    el: '#app',
    store: store,
    router,
}).$mount('#app');
