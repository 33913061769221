<template>
    <div class="card col-3 p-0 m-3">
        <!-- Main content -->
        <div class="card-body p-3">
            <ul class="list-group list-group-flush">
                <li class="list-group-item p-0" :style="editing && 'border-bottom:none'">
                    <label class="control-label small">Nom : </label>
                    <strong v-if="!editing" class="text-info">{{ client.name }}</strong>
                    <input class="form-control form-control-sm" v-else v-model="editing_client.name" type="text" :disabled="saving">
                </li>
                <li class="list-group-item p-0" :style="editing && 'border-bottom:none'">
                    <label class="control-label small">Prénom : </label>
                    <strong v-if="!editing" class="text-info">{{ client.firstname }}</strong>
                    <input class="form-control form-control-sm" v-else v-model="editing_client.firstname" type="text" :disabled="saving">
                </li>
                <li class="list-group-item p-0" :style="editing && 'border-bottom:none'">
                    <label class="control-label small">Mail :</label>
                    <strong v-if="!editing" class="text-info">{{ client.email }}</strong>
                    <input class="form-control form-control-sm" v-else v-model="editing_client.email" type="text" :disabled="saving">
                </li>
                <li class="list-group-item p-0"><label class="control-label small">Portable : </label>
                    <strong v-if="!editing" class="text-info">{{ client.phone }}</strong>
                    <input class="form-control form-control-sm" v-else v-model="editing_client.phone" type="text" :disabled="saving">
                </li>
            </ul>
        </div>
    </div>

</template>
<script>
    export default {
        props: ['client'],
        data() {
            return {
                editing_client: null,
                editing: false,
                saving: false,
            }
        },
        methods : {

        },
        mounted(){
            console.log('usercard mounted', this.client)
        },
        computed: {

        },
        components: {
        },
    }

</script>


<style scoped>
.text-info {
    font-size: 16px;
}
</style>
