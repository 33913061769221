<template>
    <div>
        <v-select :filterable="false" label="enr_ref_dossier" :options="geofoncierFolders" v-model="info.number" @search="fetchOptions" @input="geoSelected">
            <template #no-options="{ search, searching, loading }">
                <div v-if="search">
                    <div class="my-3">Aucun dossier dont le numéro commence par <strong>{{search}}</strong> n'existe sur Géofoncier. Veuillez verifier qu'il existe sur Geoprod et que celui-ci a été sychrnonisé avec Géofoncier</div>
                </div>
                <span v-else>Tapez quelques caractères pour chercher sur Geofoncier</span>
            </template>
            <template slot="option" slot-scope="option">
                <div class="d-center">
                    {{ option.enr_ref_dossier }}
                </div>
            </template>
        </v-select>
        <span v-if="!exists" class="form-text text-muted"><small><em>Commencez à entrer un numéro de dossier. Si celui-ci existe sur geofoncier, vous pourrez le choisir.</em></small></span>
        <div v-else class="alert-danger alert mt-2">
            Le dossier {{ selected }} existe déja.
        </div>
    </div>
</template>
<script>
    import axios from 'axios';

    const experts = [
        'simon.bazalgette@valoris.expert',
        'christophe.jalbaud@valoris.expert',
        'etienne.saint-aubin@valoris.expert',
        'bertrand.warin@valoris.expert',
        'jean-odon.cenac@valoris.expert',
        'virginie.jeansou@valoris.expert',
    ].map(n => n.toLowerCase())

    export default {
        name: 'NumberSelector',
        props: ['project'],
        data() {
            return {
                geofoncierFolders: [],
                info: {...this.project},
                exists: false,
                selected : null,
                requestToken: null,
            }
        },
        methods: {
            geoSelected(value) {
                console.log(value);
                if (typeof value !== 'string') {
                    // check if project already exists
                    this.exists = this.$store.state.folders.items.find(f => f.number === value.enr_ref_dossier)
                    this.selected = value.enr_ref_dossier;
                    if (this.exists) {
                        return;
                    }

                    axios.get(`/api/geo/project/${value.id}`).then(r => {
                        console.log('folder details', r)
                        const st = r.data.enr_statut;
                        // find status.
                        this.info.status = this.$store.state.status.items.find(s => {
                            if (st.toUpperCase() === 'PROJET' || st.toUpperCase() === 'Indéterminé'.toUpperCase()) {
                                return s.slug === 'non-renseigne';
                            }
                            return s.name.toUpperCase() === st.toUpperCase()
                        });
                        this.info.number = r.data.enr_ref_dossier;
                        this.info.id_geofoncier = r.data.id;
                        this.info.name = typeof r.data.enr_memo !== 'undefined' ? r.data.enr_memo.replace("\n", " ") : "";

                        axios.get(`/api/geo/ge/${r.data.enr_ge_createur}`).then(ge => {
                            if (ge.data.length) {
                                const geoUser = ge.data[0]
                                console.log('geo user', geoUser)
                                console.log('all users', this.$store.state.users.all)
                                const user = this.$store.state.users.all.find(u => {
                                    return u.firstname && u.lastname &&
                                        u.firstname.toUpperCase() === geoUser.prenom_ge.toUpperCase() &&
                                        u.lastname.toUpperCase() === geoUser.nom_ge.toUpperCase() &&
                                        experts.indexOf(u.email.toLowerCase()) >= 0 &&
                                        u.isActive
                                });

                                this.info.manager = null;
                                this.info.manager_id = null;
                                console.log('user', user)
                                if (typeof user !== 'undefined') {
                                    this.info.manager = user;
                                    this.info.manager_id = user.id;
                                }
                                this.$emit('setProjectInfo', this.info)
                            }
                        })
                        // TODO set procedure from here
                        // geofoncier operations are in r.data.op_code;
                        // op_code	[ "Eb", "Ec" ]

                        let order = 0;
                        this.info.procedure = {name: '-', steps:[]};

                        this.info.procedure.steps = r.data.op_code.map((op) => {
                            const stepType = this.$store.state.stepTypes.items.find(st => {
                                return st.code.toUpperCase() === op.toUpperCase()
                            })

                            order += 10;
                            return {
                                step_type_id: stepType.id,
                                name: stepType.name,
                                step_type: stepType,
                                status: 'planned',
                                order,
                            }
                        })

                        console.log('set procedure from geofoncier', this.info.procedure);

                        this.$emit('setProjectInfo', this.info)
                    }).catch(e => {
                        console.log(e.message, e)
                    })
                }
            },
            fetchOptions(search, loading) {
                if (!search) {
                    return
                }
                if (this.requestToken) {
                    this.requestToken.cancel()
                    this.requestToken = null;
                }
                this.exists = false;
                this.selected = null;
                loading(true);
                const CancelToken = axios.CancelToken;
                this.requestToken = CancelToken.source();

                axios.get(`/api/geo/search?search=${search}`, {
                    cancelToken: this.requestToken.token
                }).then(r => {
                    this.geofoncierFolders = r.data.results;
                    loading(false)
                }).catch(e => {
                    console.log(e.message, e)
                    loading(false)
                })
            }
        }
    }
</script>
