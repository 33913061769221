<template>
    <!-- Content Wrapper. Contains page content -->
    <div class="content-wrapper bg-light  mb-5">
        <!-- Content Header (Page header) -->
        <div class="content-header bg-light">
            <div class="container">
                <div class="row pt-2 pb-3">
                    <div class="col-10">
                        <h4 class="">Ajouter un dossier</h4>
                    </div>
                </div>
            </div>
        </div>

        <!-- /.content-header -->

        <!-- Main content -->
        <div class="content pb-5">
            <div class="container">
                <div class="row pb-4">
                    <div class="col-12">
                        <div class="border-bottom mb-4">
                            <ul class="nav nav-pills nav-fillx mb-3" id="pills-tab" role="tablist">
                                <li :class="'nav-item ' + (canStep1 ? '' : 'disabled')">
                                    <a @click="changeStep(1)" :class="classActive(1)" id="pills-infos-gen-tab">1<span class="d-none d-md-inline" style="cursor:pointer;">. Informations générales</span></a>
                                </li>
                                <li  :class="'nav-item ' + (canStep2 ? '' : 'disabled')">
                                    <a @click="changeStep(2)" :class="classActive(2)" id="pills-contacts-ehpad-tab">2<span class="d-none d-md-inline" style="cursor:pointer;">. Procédures</span></a>
                                </li>
                                <li  :class="'nav-item ' + (canStep3 ? '' : 'disabled')">
                                    <a @click="changeStep(3)" :class="classActive(3)" id="pills-inter-tab">3<span class="d-none d-md-inline" style="cursor:pointer;">. Utilisateurs</span></a>
                                </li>
                                <li  :class="'nav-item ' + (canStep4 ? '' : 'disabled')">
                                    <a @click="changeStep(4)" :class="classActive(4)" id="pills-caracteristiques-tab">4<span class="d-none d-md-inline" style="cursor:pointer;">. Documents</span></a>
                                </li>
                            </ul>
                        </div>
                        <!-- Informations générales -->
                        <div class="tab-content" id="pills-tabContent">
                            <InfosGenerales @setInfo="project=$event" v-if="grade === 1" :project="project"></InfosGenerales>
                            <Procedures :project="project" @setInfo="project.procedure=$event" v-if="grade === 2"></Procedures>
                            <Intervenants @setInfo="setContacts" :company="company" :clientType="personType" :project="project" v-if="grade === 3"></Intervenants>
                            <Documents @setInfo="project.documents=$event" :project="project" v-if="grade === 4"></Documents>
                        </div>
                    </div>
                </div>
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content -->
        <footer class="footer fixed-bottom mt-auto py-3 bg-white shadow border-top">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <button type="button" class="btn link" v-if="grade !== 1" v-on:click="grade--">
                            Précédent
                        </button>
                        <button type="button" class="btn btn-success" v-if="grade === 4" v-on:click="save()">
                            <i class="fa fa-spinner fa-spin" v-if="saving"></i> Valider
                        </button>
                        <button :disabled="!canNext" type="button" class="btn btn-primary" v-else v-on:click="next()">Suivant</button>
                    </div>
                </div>
            </div>
        </footer>
    </div>
    <!-- /.content-wrapper -->
</template>

<script>
    import axios from 'axios'
    import InfosGenerales from "./InfosGenerales.vue";
    import Procedures from "./Procedures.vue";
    import Intervenants from "./Intervenants.vue";
    import Documents from "./Documents.vue";
    import {toBase64} from '../../../utils'


    export default {
        name : "AddFolder",
        data() {
            return {
                // modal : false,
                grade : 1,
                submittedStep: false,
                saving : false,
                personType: 0,
                company: null,
                project: {
                    number: null,
                    name: null,
                    id_geofoncier: null,
                    status: null,
                    manager: null,
                    technician: null,
                    // PROCEDURES
                    procedure : {name : null, steps : []},
                    // INTERVENANTS
                    contacts : {
                        clients : null,
                        partners : null,
                    },
                    // DOCUMENTS
                    documents : {
                        bills : [],
                        contracts : [],
                    },
                },


            }
        },
        mounted() {
            axios.get('/api/users/manager').then(r => this.$store.commit('setManagers', r.data))
            axios.get('/api/document_types').then(resp => {
                console.log('got doc type', resp);
            });
            axios.get('/api/companies').then(r => this.$store.commit('setCompanies', r.data))
        },
        computed: {
            canStep1() {
                return true;
            },
            canStep2() {
                return this.project.number && this.project.status && this.project.manager;
            },
            canStep3() {
                return this.project.number && this.project.status && this.project.manager;
            },
            canStep4() {
                console.log('project contacts', this.project.contacts)
                return this.project.number && this.project.status && this.project.manager
                    && this.project.contacts && this.project.contacts.clients && this.project.contacts.clients.length;
            },
            canNext() {
                return this['canStep'+(this.grade+1)];
            }
        },
        methods: {
            async next(){
                this.grade++;
            },
            async save() {
                this.saving = true;
                // Remove procedure id for new project so that it is saved as a new procedure
                delete this.project.procedure.id;

                const project = {...this.project};
                project.manager_id = project.manager.id;
                project.technician_id = project.technician ? project.technician.id : null;
                project.status_id = project.status.id;
                project.client_type = this.personType;


                console.log('sending', project);

                // save on server and redirect to page
                axios.post('/api/folders', project).then((r) => {
                    this.saving = false;
                    this.$store.commit('addFolder', r.data);
                    this.$router.push(`/dossier/${encodeURIComponent(r.data.number)}`)

                    // SET STEP TYPES
                    axios.get('/api/step_types')
                    .then(s => {
                        this.$store.commit('setStepTypes', s.data);
                    }).catch(err => {
                        console.log('could not get types', err)
                    })
                }).catch(e => {
                    alert(e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message);
                    this.saving = false;
                })
            },

            classActive(id){
                if (this.grade === id){
                    return "nav-link small active";
                }
                else {
                    return "nav-link small";
                }
            },
            setContacts(val) {
                this.company = val.company;
                this.personType = val.personType
                this.project.contacts = val.contacts;
            },
            changeStep(nextStep) {
                if (this['canStep'+nextStep]) {
                    this.grade = nextStep;
                }
            }
        },
        components: {
            InfosGenerales,
            Procedures,
            Intervenants,
            Documents,
        },
    }

</script>
<style scoped>
footer {
    z-index:90;
}
.text-info {
    font-size: 16px;
    cursor: pointer;
}
</style>
<style>

.nav-pills .disabled a span {
    cursor: default !important;
}
.nav-pills .disabled .nav-link:not(.active):hover {
    color: #999;

}
</style>
