import App from '../App.vue';
import Login from '../components/Login.vue';
import Account from '../components/account/index.vue';
import Folders from '../components/folders/index.vue';
import NewFolder from '../components/folders/add/index.vue';
import Folder from '../components/folders/single/index.vue';
import Contract from '../components/contracts/index.vue';
import Users from '../components/users/';

export default [
    { path: '/', component: App, children: [
        { path: '', component: Folders },
        { path: '/login', component: Login },
        { path: '/compte', component: Account },
        { path: '/dossiers', component: Folders },
        { path: '/dossiers/add', component: NewFolder },
        { path: '/dossier/:number', component: Folder },
        { path: '/contrats', component: Contract },
        { path: '/utilisateurs/:id', component: Users },
        { path: '/utilisateurs', component: Users },

    ] },

];