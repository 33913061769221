<template>
    <div>
        <div v-if="user && user.role && (user.role.slug === 'admin' || user.role.slug === 'manager')" class="row">
            <!-- <p style="font-size: 80%;margin-left: 15px; margin-top: 5px">Accès partenaires</p>
            <label class="switch" style="margin-top: 7px;margin-left: 5px">
                <input type="checkbox" v-model="step.partner_access" :checked="step.partner_access" @change="saveStep(step)">
                <span class="slider round"></span>
            </label> -->

            <p style="font-size: 80%;margin-left: 25px">Statut de l'étape
                <v-select style="display:inline-block; width: 120px" label="name" :options="status" v-model="step.status" @input="saveStep(step)" :reduce="s => s.slug" :clearable="false"></v-select>
            </p>
        </div>
        <div v-else class="row">
            <p style="font-size: 80%;margin-left: 25px">Statut de l'étape&nbsp;:
                <b>{{stepStatus.name}}</b>
            </p>
        </div>

        <!-- v-if=" user.role_id === 1" -->
        <span class=" position-absolute"  :style="(user.role.slug === 'admin' || user.role.slug === 'manager') ? 'top:0px;right:20px;' : 'top:15px;right:20px;'">
            <button v-if="(user.role.slug === 'admin' || user.role.slug === 'manager')" class="btn btn-sm btn-link text-info d-block text-sm" @click="modal='addDoc'">
                <i class="fas fa-plus"></i> <span class="ml-1">Ajouter un document</span> <br/>
            </button>
            <a class="btn btn-sm btn-link text-info d-block text-sm" :href="`/api/folders/${folder.id}/procedures/${folder.procedure.id}/steps/${step.id}/documents.zip`" v-if="step.documents && step.documents.length">
                <i class="fas fa-file-download"></i> <span class="ml-1">Télécharger tous les documents</span>
            </a>
            <button
                class="btn btn-sm btn-link text-info d-block text-sm"
                v-if="step.documents && step.documents.length && (user.role.slug === 'admin' || user.role.slug === 'manager')"
                @click="modal = 'notify'"
            >
                <i class="fas fa-paper-plane"></i>
                <span class="ml-1">Notifier tous les documents</span>
            </button>
        </span>
        <button title="Supprimer l'étape" v-if="user && user.role && (user.role.slug === 'admin' || user.role.slug === 'manager')" class="btn btn-link text-danger" @click="deleteStep" style="position:absolute; bottom: 0;right: 0">
            <i class="fas fa-spin fa-spinner" v-if="deleting"></i>
            <i class="fas fa-trash" v-else></i>
        </button>
        <AddDocument :clients="folder.clients" :partners="folder.partners" :i="i" :step="step" :project="folder" v-if="modal === 'addDoc'" @close="modal=false"></AddDocument>
        <NotifyDocument :folder="folder" :step="step" :documents="step.documents" v-if="modal==='notify'" @close="modal=false" @success="success"></NotifyDocument>
        <Toast body="Votre message a bien été envoyé" title="Envoi réussi" v-if="sendSuccess"></Toast>

    </div>
</template>
<script>
    import axios from 'axios'
    import AddDocument from '../modals/AddDocument.vue';
    import NotifyDocument from '../modals/NotifyDocument.vue';
    import Toast from '../../../../utils/Toast'

    export default {
        name: 'StepHeader',
        props: ['step', 'folder', 'i'],
        data() {
            return {
                modal: false,
                notify: false,
                deleting: false,
                sendSuccess: false,
            }
        },
        methods: {
            saveStep(){
                axios.put(`/api/folders/${this.folder.id}/procedures/${this.folder.procedure.id}/steps/${this.step.id}`, this.step).then(res =>{
                    this.folder.procedure.steps.forEach(element => {
                        if (element.id === res.data.id){
                            element.partner_access = res.data.partner_access;
                            element.status = this.step.status;
                        }
                    })}
                );
            },
            success() {
                this.modal = false
                this.sendSuccess = true
                setTimeout(() => {
                    this.sendSuccess = false
                }, 4000)
            },
            deleteStep(){
                if (confirm(`Vous allez supprimer l'étape ${this.step.name}. Cette opération est irréversible. Les documents liés à l'étape seront cependant conservés. Souhaitez-vous continuer ?`)) {
                    this.deleting = true;
                    axios.delete(`/api/folders/${this.folder.id}/procedures/${this.folder.procedure.id}/steps/${this.step.id}`).then(res =>{
                        axios.get('/api/folders').then(r => {
                            this.$store.commit('setFolders', r.data);
                            this.deleting = false;
                        });
                    });
                }
            }
        },
        computed: {
            user() {
                return this.$store.state.user.data
            },
            status() {
                return this.$store.state.stepStatuses.items;
            },
            stepStatus() {
                return this.$store.state.stepStatuses.items.find(s => s.slug === this.step.status);
            },
        },
        components: {
            AddDocument,
            NotifyDocument,
            Toast,
        },
    }
</script>

<style scoped>


</style>
