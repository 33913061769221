<template>
    <div class="modal-backdrop">
        <div class="modal" tabindex="-1" role="dialog" style="display: block" v-on:click.self="$emit('close')">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" v-if="user && user.id">Editer un utilisateur</h5>
                        <h5 class="modal-title" v-else>Créer un utilisateur</h5>
                        <button type="button" class="close" v-on:click="$emit('close')" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body p-3">
                        <form>
                            <div class="form-row" v-if="user && user.role && user.role.slug !== 'client'">
                                <div class="form-group col-md-3">
                                    <label class="control-label small"><b>Société&nbsp;:</b></label>
                                </div>
                                <div class="form-group col-md-7">
                                    <v-select taggable :options="$store.state.companies.items" v-model="company" label="name"></v-select>

                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <label class="control-label small" :class="{ 'text-danger': submitted && $v.lastname.$error }"><b>Nom&nbsp;:</b></label>
                                </div>
                                <div class="form-group col-md-7" v-if="user && user.role && user.role.slug !== 'client'">
                                    <input type="text" class="form-control" v-model="lastname" :class="{ 'is-invalid': submitted && $v.lastname.$error }">
                                    <small class="text-danger" v-if="$v.lastname.$error"><b>Nom obligatoire</b></small>
                                </div>
                                <div class="form-group col-md-7" v-else>
                                    {{lastname}}
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <label class="control-label small" :class="{ 'text-danger': submitted && $v.firstname.$error }"><b>Prénom&nbsp;:</b></label>
                                </div>
                                <div class="form-group col-md-7" v-if="user && user.role && user.role.slug !== 'client'">
                                    <input type="text" class="form-control" v-model="firstname" :class="{ 'is-invalid': submitted && $v.firstname.$error }">
                                    <small class="text-danger" v-if="$v.firstname.$error"><b>Prénom obligatoire</b></small>
                                </div>
                                <div class="form-group col-md-7" v-else>
                                    {{firstname}}
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <label class="control-label small" :class="{ 'text-danger': submitted && $v.email.$error }"><b>E-mail&nbsp;:</b></label>
                                </div>
                                <div class="form-group col-md-7" v-if="user && user.role && user.role.slug !== 'client'">
                                    <input type="text" class="form-control" v-model="email" :class="{ 'is-invalid': submitted && $v.email.$error }">
                                    <small class="text-danger" v-if="$v.email.$error">E-mail obligatoire</small>
                                </div>
                                <div class="form-group col-md-7" v-else>
                                    {{email}}
                                </div>
                            </div>
                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <label class="control-label small"><b>Portable&nbsp;:</b></label>
                                </div>
                                <div class="form-group col-md-7" v-if="user && user.role && user.role.slug !== 'client'">
                                    <input class="form-control" type="text" v-model="phone">
                                </div>
                                <div class="form-group col-md-7" v-else>
                                    {{phone}}
                                </div>
                            </div>


                            <div class="form-row" v-if="isAdmin">
                                <div class="form-group col-md-3">
                                    <label class="control-label small"><b>Rôle&nbsp;:</b></label>
                                </div>
                                <div class="form-group col-md-7">
                                    <v-select :options="roles" v-model="role" label="name"></v-select> <!-- {name: 'Partenaire', slug: 'partner'}, -->
                                </div>
                            </div>

                            <div class="form-row" v-if="isAdmin">
                                <div class="form-group col-md-3">
                                    <label class="control-label small"><b>Utilisateur Actif&nbsp;:</b></label>
                                </div>
                                <div class="form-group col-md-7">
                                    <input type="checkbox" v-model="isActive">
                                </div>
                            </div>

                            <div class="form-row" v-if="user && loggedInUser && (loggedInUser.id === user.id)">
                                <div class="form-group col-md-3">
                                    <label class="control-label small"><b>Nouveau mot de passe :</b></label>
                                </div>
                                <div class="form-group col-md-7">
                                    <input class="form-control" type="password" v-model="password">
                                </div>
                            </div>

                        </form>
                    </div>
                    <div class="modal-footer">
                        <div class="row">
                            <div class="col-sm-6 text-sm text-muted" v-if="isAdmin">
                                Ces modifications ne seront pas synchronisées sur Geoprod
                            </div>
                            <div :class="isAdmin ? 'col-sm-6' : 'col-sm-12'">
                                <div class="float-right">
                                    <button type="button" class="btn btn-light" @click="$emit('close')">Annuler</button>
                                    <button type="button" class="btn btn-primary" v-on:click="save()">
                                        <i class="fa fa-spinner fa-spin" v-if="saving"></i>
                                        Valider
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { required, email } from 'vuelidate/lib/validators'
    import Vuelidate from 'vuelidate'
    import axios from "axios";

    export default {
        props: ['user', 'newUser'],
        name: 'EditUserModal',
        data() {
            return {
                company: this.user && this.user.company ? this.user.company : null,
                lastname : this.user && this.user.lastname ? this.user.lastname : null,
                firstname : this.user && this.user.firstname ? this.user.firstname : null,
                email : this.user && this.user.email ? this.user.email : null,
                phone : this.user && this.user.phone ? this.user.phone : null,
                role : this.user && this.user.role ? this.user.role : null,
                isActive : this.user && this.user.isActive ? this.user.isActive : false,
                password: null,
                saving : false,
                submitted : false,
            }
        },
        validations: {
            lastname: { required },
            firstname: { required },
            email: { required, email },
            isActive: { required },
        },
        methods: {
            async save () {
                this.saving = true
                this.submitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.saving = false
                    return
                }

                console.log('this.company', this.company)

                const data = {
                    firstname: this.firstname,
                    lastname: this.lastname,
                    email: this.email,
                    phone: this.phone,
                    company: this.company ? this.company.name : null,
                    isActive: this.isActive,
                }

                if (this.user) {
                    data.id = this.user.id;
                }
                if (this.password) {
                    data.password = this.password;
                }
                if (this.role) {
                    data.role = this.role.slug;
                }

                this.$store.dispatch('saveUser', data).then((r) => {
                    this.saving = false
                    if(r.data.id === this.$store.state.user.data.id) {
                        this.$store.commit('setUser', r.data)
                    } else {
                        axios.get('/api/users').then(r => this.$store.commit('setUsers', r.data))
                    }
                    this.$emit('close')
                })
                .catch((err) => {
                    this.saving = false
                })
            },
        },
        computed: {
            isAdmin() {
                return this.$store.state.user.data && this.$store.state.user.data.role && this.$store.state.user.data.role.slug === 'admin';
            },
            loggedInUser() {
                return this.$store.state.user.data;
            },
            roles() {
                if (this.newUser) {
                    return [{name: 'Gestionnaire', slug: 'manager'},{name: 'Administration', slug: 'admin'},]
                }
                return [{name: 'Gestionnaire', slug: 'manager'},{name: 'Client', slug: 'client'},{name: 'Administration', slug: 'admin'},]
            }
        }
    }
</script>
