import axios from 'axios';

export default {
    state: { items: [] },
    mutations: {
        setFolders (state, items) {
            state.items = items;
        },
        addFolder (state, folder) {
            state.items = [...state.items, folder];
        },
    },
    actions: {
        updateProcedureSteps({state, commit}, {steps, folder_id}) {
            console.log('steps, folder_id', steps, folder_id)

            const folder = state.items.find(f => f.id === folder_id)

            if (!folder.procedure) {
                // No procedure on this folder, ignore
                return;
            }

            console.log('step', steps)

            //remove delete steps from server
            const oldStepsIds = folder.procedure && folder.procedure.steps ? folder.procedure.steps.map(s => s.id) : []
            const keep = steps.map(s => s.id)
            let toDelete = oldStepsIds.filter(x => !keep.includes(x));
            const deleted = toDelete.map(id => {
                return axios.delete(`/api/folders/${folder.id}/procedures/${folder.procedure.id}/steps/${id}`)
            })


            // update steps on server
            const res = steps.map(s => {
                if (s.id) {
                    return axios.put(`/api/folders/${folder.id}/procedures/${folder.procedure.id}/steps/${s.id}`, s)
                } else {
                    s.folder_id = folder_id;
                    return axios.post(`/api/folders/${folder.id}/procedures/${folder.procedure.id}/steps`, s)
                }
            })

            Promise.all([...res, ...deleted]).then(() => {
                axios.get('/api/folders').then(r => commit('setFolders', r.data));
            })
        },
        deleteFolder({state, commit}, folder) {
            const oldFolders = [...state.items];
            const newFolders = state.items.filter(f => f.id !== folder.id);
            commit('setFolders', newFolders)

            const req = axios.delete(`/api/folders/${folder.id}`)
            req.then(() => {

            }).catch(e => {
                alert(e.message)
                commit('setFolders', oldFolders)
            })
            return req;
        }
    }
};