<template>
    <!-- Main content -->
    <div class="content py-5">
        <div class="container">
            <h1 class="mb-5">Contrats et factures</h1>
            <div class="folders row mb-4" v-for="folder in folders" :key="folder.id">
                <div class="col-md-7">
                    <h4>
                    <router-link :to="`dossier/${encodeURIComponent(folder.number)}`" class=""> {{ folder.name }}</router-link>
                    - {{ folder.status.name }}
                    </h4>
                        <p>Numéro de dossier : {{folder.number}}</p>
                </div>
                <div class="col-md-5 row">
                    <div class="col-md-12 text-right pt-4">
                        <a v-if="folder.documents && folder.documents.length" :href="`/api/folders/${folder.id}/fc.zip`" class="text-sm text-info pt-0 mx-2">
                            <i class="fas fa-download"></i>
                            <span class="ml-1">Tout télécharger</span>
                        </a>
                    </div>
                </div>
                <div class="table-responsive">
                    <table class="table table-borderedx table-striped">
                        <thead>
                        <tr>
                            <th>
                                Date d'envoi
                            </th>
                            <th>
                                Date du document
                            </th>
                            <th>
                                Nom du document
                            </th>
                            <th>
                                Type du document
                            </th>
                            <th>
                                Téléchargements
                            </th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody v-if="folder.documents.length">
                            <tr v-for="file in folder.documents" :key="file.id">
                                <td>
                                    {{ new Date(file.created_at).toLocaleDateString('fr') }}
                                </td>
                                <td>
                                    <strong>{{ file.date ? new Date(file.date).toLocaleDateString('fr') : '' }}</strong>
                                </td>
                                <td>
                                    <strong>{{ file.customName ? file.customName : file.name }}</strong>
                                </td>
                                <td>
                                    {{file.document_type ? file.document_type.name : ''}}
                                </td>
                                <td>
                                    <span v-for="dl in file.downloads">
                                        <span v-if="dl.user.role && dl.user.role.slug === 'client'">
                                            <strong>{{dl.user.firstname}} {{dl.user.lastname}}</strong>
                                            le <span class="text-muted">{{(new Date(dl.updated_at)).toLocaleDateString('fr')}}</span>
                                        </span>
                                    </span>
                                </td>
                                <td class="text-right">
                                    <a :href="file.url" title="télécharger"><i class="far fa-file-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td class="p-4 text-center text-muted" colspan="5">Aucun documents</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>

    export default {
        name : 'Contracts',
        data() {
            return {
            }
        },
        mounted(){
            
        },
        computed: {
            folders () {
                return this.$store.state.folders.items ? this.$store.state.folders.items.map(f => {
                    f.documents = f.documents.filter(d => d.document_type.slug === 'bill' || d.document_type.slug === 'contract')
                    return f;
                }) : []
            }
        },
    }

</script>


<style scoped>
.custom-dropdown {
        background-color: #f2f2f2;
        border-color: #f2f2f2;
        color: black;
    }
.edit-button {
    right: 0;
}
</style>