<template>
    <tr>
        <td>
            <router-link :to="`/utilisateurs/${user.id}`" v-if="loggedInUser && loggedInUser.role && (loggedInUser.role.slug === 'admin' || loggedInUser.role.slug === 'manager')">
                <span v-if="user.firstname">{{ user.firstname }}</span>
                <span v-if="user.lastname">{{ user.lastname }}</span>
                <span v-if="!user.lastname && !user.firstname">{{ user.name }}</span>
            </router-link>
            <span v-else>
                <span v-if="user.firstname">{{ user.firstname }}</span>
                <span v-if="user.lastname">{{ user.lastname }}</span>
                <span v-if="!user.lastname && !user.firstname">{{ user.name }}</span>
            </span>
        </td>
        <td>
            {{ user.email}}
        </td>
        <td>
            {{ user.company ? user.company.name : '-' }}
        </td>
        <td>
            <button v-if="canDelete" class="btn btn-link text-danger" @click="removeContact">
                <i class="fas fa-spin fa-spinner" v-if="saving"></i>
                <i class="fas fa-trash" v-else></i>
            </button>
        </td>
    </tr>
</template>
<script>
    import axios from 'axios';

    export default {
        name: 'UserLine',
        props: ['user', 'folder', 'canDelete'],
        data() {
            return {
                saving: false,
            }
        },
        computed: {
            loggedInUser() {
                return this.$store.state.user.data;
            }
        },
        methods: {
            removeContact() {
                if (confirm('Ce contact client va être supprimé de ce dossier. Souhaitez-vous continuer ?')) {
                    this.saving = true;
                    console.log('this.user', this.user);
                    axios.delete(`/api/folders/${this.folder.id}/contacts/${this.user.id}/role/${this.user.pivot.role_id}`).then(() => {
                        axios.get('/api/folders').then(r => this.$store.commit('setFolders', r.data)).then(() => {
                            this.saving = false;
                        });
                    }).catch(er => {
                        // TODO make sure this is the correct way of getting the message
                        console.log(er.response)
                        alert("Ce contact n'a pas pu être supprimé")
                    })
                }
            }
        }
    }
</script>
