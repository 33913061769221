<template>
    <li :class="'list-group-item' + (content.selected ? ' active' : '')" style="cursor: pointer">
        <div @click.prevent.self="clicked(content)">
            <i class="fas fa-spinner fa-spin" v-if="loading"></i>
            <i class="fas fa-folder" v-else-if="content.type==='dir'"></i>
            <i class="fas fa-file" v-else></i>
            {{content.basename}}
            <button @click.prevent="selectFolder(content)" v-if="selectType==='folder' && content.type === 'dir' && !content.selected" class="btn btn-default float-right">Choisir</button>
        </div>

        <Nextcloud v-if="content.children && open" :contents="content.children" :selectType="selectType" :openFolder="openFolder"></Nextcloud>
    </li>
</template>

<script>
    import axios from 'axios'

    export default {
        name: 'NextcloudItem',
        props: ['content', 'selectType', 'openFolder'],
        data() {
            return {
                open: false,
                loading: false,
            }
        },
        mounted() {
            const currentPath = this.content.path.replace('remote.php/webdav/'+window.WEBDAV_ROOT, '')
            const currentParts = currentPath.split('/');
            const folderParts = this.openFolder.split('/');

            let isChild = currentParts.reduce((a, p, i) => {
                return p === folderParts[i];
            }, true)

            if (this.openFolder && isChild) {
                // Open this folder automatically
                // Example
                // this.openFolder = /DATAKODE/DOSSIERS/2020/projet/
                // currentPath = /DATAKODE/DOSSIERS/
                // pathLeft = 2020/projet/
                //The part of the path that we still haven't done
                const pathLeft = this.openFolder.replace(currentPath, '').replace(/\/\//, '');

                // If we have no path left, it means we have done all the directories, so just do the actual one
                const url = pathLeft.length ? `${currentPath}/${pathLeft.split('/')[0]}` : this.openFolder;
                if (!this.content.children || pathLeft.length === 0) {
                    this.loading = true;

                    axios.get(`/api/fs/ls/${url}`).then(f => {
                        console.log('ls done', f.data)
                        console.log('path', this.content.path)
                        this.$store.commit('addChildren', {
                            path: this.content.path,
                            items: f.data
                        })
                        console.log('nextcloud items', this.$store.state.nextcloud.items)
                        this.open = true;
                        this.loading = false;
                    }).catch(err => {
                        console.log('get folder error', err)
                        this.loading = false;
                    })
                } else {
                    // Open directory
                    this.open = !this.open;
                }
            }
        },
        methods: {
            clicked(item) {
                if (item.type === 'dir') {
                    if (!item.children) {
                        // Download children of this directory
                        this.loading = true;
                        const path = item.path.replace('remote.php/webdav/'+window.WEBDAV_ROOT, '')
                        axios.get(`/api/fs/ls/${path}`).then(f => {
                            this.$store.commit('addChildren', {path: item.path, items: f.data})
                            this.open = true;
                            this.loading = false;
                        }).catch(err => {
                            this.loading = false;
                        })
                    } else {
                        // Open directory
                        this.open = !this.open;
                    }
                } else if (this.selectType === 'file') {
                    //Set file as selected
                    this.$store.commit('setSelected', item)
                }
            },
            selectFolder(item) {
                this.$store.commit('setSelected', item)
            }
        },
        components: {
            Nextcloud: () => import('./index.vue'),
        }
    }
</script>