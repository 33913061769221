<template>
    <!-- Main content -->
    <div class="content pt-4">
        <div class="container">
            <h3 class="mb-4">Bienvenue dans votre compte.</h3>
            <div class="row">
                <div class=" col-md-7">
                    <div class=" mb-3" v-if="user && user.role && user.role.slug !== 'manager' && user.role.slug !== 'admin'">
                        <button type="button" class="btn btn-outline-secondary mb-2" @click="$router.push('/dossiers')">
                            <i class="far fa-eye"></i> Tous vos dossiers
                        </button> <br/>
                        <button type="button" class="btn btn-outline-secondary" @click="$router.push('/contrats')">
                            <i class="far fa-eye"></i> Tous vos contrats et factures
                        </button>
                    </div>

                    <h4 class="float-left">Informations de compte</h4>
                    <!-- EDIT SEULEMENT MDP ET EMAIL -->
                    <button role="button" class="btn link text-info" @click="userModalOpen=true"><i class="fas fa-cog"></i> Gérer le compte</button>

                    <EditUserModal :user="$store.state.user.data" v-if="userModalOpen" @close="userModalOpen=false"></EditUserModal>

                    <ul class="list-group list-group-flush w-75" v-if="user">
                        <li class="list-group-item"><small>Société :</small> <strong> {{ user.company ? user.company.name : 'Particulier' }} </strong></li> <!-- TO DO -->
                        <li class="list-group-item"><small>Nom :</small> <strong>{{ user.name }}</strong></li>
                        <!-- <li class="list-group-item"><small>Prénom :</small> <strong>Stéphane</strong></li> -->
                        <li class="list-group-item"><small>Mail : </small><strong>{{ user.email }}</strong>
                        </li>
                        <li class="list-group-item"><small>Portable :</small> <strong>{{ user.phone }}</strong></li>
                        <li class="list-group-item"><small>Mot de passe :</small> <strong>******</strong>
                        </li>
                    </ul>

                </div>
                <div class="col-md-5">
                    <div class="card bg-white">
                        <div class="card-header">
                            <h4 class="card-title mb-0">Dernier dossier consulté</h4>
                            <!-- /.card-tools -->
                        </div>
                        <!-- /.card-header -->

                        <div class="card-body">
                            <div v-if="user.last_viewed_folder">
                                <router-link :to="`/dossier/${user.last_viewed_folder.number}`" class="stretched-link">{{user.last_viewed_folder.name}}</router-link> <br/>
                                {{user.last_viewed_folder.address}}
                            </div>
                            <div class="text-muted text-center" v-else >
                                Aucun dossier consulté
                            </div>
                        </div>
                        <!-- /.card-body -->
                    </div>
                    <!-- /.card -->

                    <div class="card bg-white my-3">
                        <div class="card-header">
                            <h4 class="card-title mb-0">Paramètres</h4>
                        </div>
                        <!-- /.card-header -->
                        <div class="card-body">
                            <div class="row">
                                <div class="col-md-8">Recevoir les notifications</div>
                                <label class="switch" style="margin-top: 7px;margin-left: 5px">
                                    <input type="checkbox" v-model="user.notifications" :checked="user.notifications" @change="setNotifications">
                                    <span class="slider round"></span>
                                </label>
                            </div>
                        </div>
                        <!-- /.card-body -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import EditUserModal from "../users/EditUserModal";

    export default {
        name : "Compte",
        data() {
            return {
                userModalOpen: false,
            }
        },
        mounted(){
            console.log('compte', this.$store.state.user.data)
        },
        methods: {
            setNotifications() {
                console.log('notif set to ', this.user.notifications)
                const data = {
                    ...this.user,
                    company: this.user.company ? this.user.company.name : null,
                    role: this.user.role ? this.user.role.slug : null,
                };

                this.$store.dispatch('saveUser', data).then((r) => {
                    this.saving = false
                    if(r.data.id === this.$store.state.user.data.id) {
                        this.$store.commit('setUser', r.data)
                    } else {
                        axios.get('/api/users').then(r => this.$store.commit('setUsers', r.data))
                    }
                    this.$emit('close')
                })
                .catch((err) => {
                    this.saving = false
                    alert(err.message)
                })
            }
        },
        computed : {
            user: function () {
                return this.$store.state.user.data
            }
        },
        components: {
            EditUserModal,
        }
    }

</script>


<style scoped>
.custom-dropdown {
        background-color: #f2f2f2;
        border-color: #f2f2f2;
        color: black;
    }
/* .edit-button {
    left: 20px;
    top: 5px;
} */
ul > li {
    padding: 5px 20px;
}
.list-group-item > strong {
    font-size: 18px!important;

}
</style>
