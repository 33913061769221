<template>
    <div class="content py-5">
        <div class="container">
            <div class="form-row">
                <div class="states col" data-children-count="1">
                    <span class="small font-weight-bold">Rechercher</span>
                    <input type="text" class="form-control" placeholder="Nom, email" v-model="listControl.filter">
                </div>
                <div class="states col" data-children-count="1">
                    <span class="small font-weight-bold">Rôle</span>
                    <v-select label="name" :options="[{name: 'Tous', slug: 'tous'},{name: 'Gestionnaire', slug: 'manager'},{name: 'Client', slug: 'client'},{name: 'Administration', slug: 'admin'},]" v-model="listControl.role"></v-select> <!-- {name: 'Partenaire', slug: 'partner'}, -->

                </div>
                <div class="states col" data-children-count="1">
                    <span class="small font-weight-bold">Trier</span>
                    <select class="form-control form-control-sm custom-select" v-model="listControl.order">
                        <option value="created_at">Le plus récent</option>
                        <option value="email">Adresse email</option>
                        <option value="lastname">Nom</option>
                        <option value="company">Société</option>
                    </select>
                </div>
            </div>
            <div class="row my-4">
                <div class="col-6" >
                    <h5>Votre liste : {{users ? users.length : ''}} résultats</h5>
                </div>
                <div class="col-6" >
                    <button class="btn btn-primary float-right" @click="addUser">
                        <i class="fas fa-plus"></i>
                        Ajouter un utilisateur
                    </button>
                </div>
            </div>

            <div class="row">
                <div class="table-responsive">
                    <table class="table table-borderedx table-striped">
                        <thead>
                        <tr>
                            <th>Nom</th>
                            <th>Société</th>
                            <th>Email</th>
                            <th>Rôle</th>
                            <th>Actif</th>
                            <th>Notification</th>
                            <th>Date de création</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="user in users" :key="user.id">
                            <td>{{user.firstname}} {{user.lastname}}</td>
                            <td>{{user.company ? user.company.name : '-'}}</td>
                            <td>{{user.email}}</td>
                            <td>{{user.role.name}}</td>
                            <td>{{user.isActive ? 'Oui' : 'Non'}}</td>
                            <td>{{user.notifications ? 'Oui' : 'Non'}}</td>
                            <td>{{(new Date(user.created_at)).toLocaleDateString('fr')}}</td>
                            <td style="white-space: nowrap;text-align: right">
                                <button class="btn btn-flat m-0 p-0 mr-1 text-secondary" @click="edit(user)" v-if="user.role && user.role.slug !== 'client' && user.role.slug !== 'partner'">
                                    <i class="fas fa-edit"></i>
                                </button>
                                <button class="btn btn-flat text-danger m-0 p-0" @click="remove(user)">
                                    <i class="fas fa-spinner fa-spin" v-if="deletingUser && user.id === deletingUser.id"></i>
                                    <i class="fas fa-ban" v-else></i>
                                </button>
                            </td>
                        </tr>
                        <tr v-if="!users || users.length === 0">
                            <td colspan="7">
                                <p class="text-center">Aucun résultat</p>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <EditUserModal :newUser="newUser" :user="user" v-if="userModalOpen" @close="userModalOpen=false;$router.push('/utilisateurs')"></EditUserModal>
    </div>
</template>
<script>
    import axios from 'axios';

    import EditUserModal from './EditUserModal.vue';

    export default {
        name: 'Users',
        data() {
            return {
                listControl: {
                    role: null,
                    sort: 'created_at',
                    filter: null,
                },
                user: null,
                newUser: false,
                userModalOpen: false,
                createUserModalOpen: false,
                deletingUser: null,
            }
        },
        methods: {
            create() {
                // Open modal with new user
                this.createUserModalOpen = true;
            },
            edit(user) {
                // Open modal with correct user
                this.user = user;
                this.newUser = false;
                this.userModalOpen = true;
            },
            remove(user) {
                if (confirm(`Vous allez supprimer ${user.firstname} ${user.lastname}. Cette action est irréversible. Souhaitez-vous continuer ?`)) {
                    this.deletingUser = user;
                    this.$store.dispatch('deleteUser', user).then(() => {
                        this.deletingUser = null;
                    }).catch((e) => {
                        this.deletingUser = null;
                        alert(`impossible de supprimer l'utilisateur ${user.firstname} ${user.lastname}. ` + e.message)
                    })
                }
            },
            addUser() {
                this.user = {role: {name: 'Gestionnaire', slug: 'manager'}};
                this.newUser = true;
                this.userModalOpen = true;
            }
        },
        computed: {
            users() {
                let users = this.$store.state.users.all ? this.$store.state.users.all.sort((a, b) => {
                    if (this.listControl.order === 'created_at') {
                        return new Date(b.created_at) < new Date(a.created_at) ? 1 : -1;
                    }
                    if (this.listControl.order === 'company') {
                        const ac = a.company ? a.company.name : 'zzzz';
                        const bc = b.company ? b.company.name : 'zzzz';
                        return ac > bc ? 1 : -1;
                    }
                    return a[this.listControl.order] > b[this.listControl.order] ? 1 : -1;
                }) : [];

                if (this.listControl.role && this.listControl.role.slug !== 'tous') {
                    users = users.filter(u => u.role.slug === this.listControl.role.slug)
                }

                if (this.listControl.filter) {
                    users = users.filter( u => {
                        return (
                            u.name.toUpperCase().includes(this.listControl.filter.toUpperCase()) ||
                            u.email.toUpperCase().includes(this.listControl.filter.toUpperCase())
                        )
                    })
                }

                return users;
            },
            selectedUser() {
                const user = this.users.find(u => u.id === parseInt(this.$route.params.id, 10))

                if (user) {
                    this.edit(user);
                }
            }
        },
        mounted() {
            // Refresh users and companies
            axios.get('/api/users').then(r => this.$store.commit('setUsers', r.data))
            axios.get('/api/companies').then(r => this.$store.commit('setCompanies', r.data))
            console.log('params', this.$route.params.id)
        },
        components: {
            EditUserModal,
        }
    }
</script>
