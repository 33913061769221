<template>
<div class="modal-backdrop">
    <div class="modal" tabindex="-1" role="dialog" style="display: block" v-on:click.self="$store.commit('hideModal')">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Détails du dossier<br/>
                    </h5>
                    <button type="button" class="close" v-on:click="$store.commit('hideModal')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                    <div class="modal-body p-3">
                        <form  class="form form-horizontal">
                            <div class="form-group">
                                <label class="control-label small"><strong>Numéro de dossier</strong></label>
                                <NumberSelector :project="editedProject" @setProjectInfo="setInfo"></NumberSelector>
                            </div>
                            <div class="form-group">
                                <label for="pname">Nom du projet</label>
                                <input class="form-control" id="pname" type="text" v-model="editedProject.name">
                            </div>
                            <div class="form-group">
                                <label class="control-label small"><strong>Géomètre expert</strong></label>
                                <v-select label="name" :options="managers"  v-model="editedProject.manager"></v-select>
                            </div>
                            <div class="form-group">
                                <label class="control-label small"><strong>Responsable du dossier</strong></label>
                                <v-select label="name" :options="managers"  v-model="editedProject.technician"></v-select>
                            </div>
                            <div class="form-group">
                                <label class="control-label small"><strong>Statut</strong></label>
                                <v-select label="name" :options="$store.state.status.items"  v-model="editedProject.status"></v-select>
                            </div>

                            <div class="form-group">
                                <label class="control-label small"><strong>Date de livraison prévue *</strong></label>
                                <DatePicker format="dd/MM/yyyy" :language="fr" v-model="editedProject.date" name="date" input-class="form-control" :typeable="true"></DatePicker>

                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-primary" @click.prevent="save">
                            <i class="fas fa-spin fa-spinner" v-if="saving"></i>
                            <i class="fas fa-save" v-else></i>
                            Enregistrer</button>
                        <button type="button" class="btn btn-light" @click="$store.commit('hideModal')">Fermer</button>
                    </div>

            </div>
        </div>
    </div>
    <!-- END MODAL -->
</div>
</template>
<script>
    import DatePicker from '@sum.cumo/vue-datepicker'
    import '@sum.cumo/vue-datepicker/dist/vuejs-datepicker.css'
    import {fr} from '@sum.cumo/vue-datepicker/dist/locale'

    import axios from 'axios'
    import NumberSelector from '../../add/components/NumberSelector.vue'

    export default {
        name : 'EditDetailsModal',
        props: ['project'],
        data() {
            return {
                editedProject: {...this.project},
                saving: false,
                fr,
            }
        },
        methods: {
            save() {
                this.saving = true;
                const tosend = {...this.editedProject};

                tosend.manager_id = this.editedProject.manager.id
                tosend.technician_id = this.editedProject.technician ? this.editedProject.technician.id : null;
                tosend.status_id = this.editedProject.status.id
                tosend.date = this.editedProject.date

                axios.put(`/api/folders/${tosend.id}`, tosend).then((res) => {
                    axios.get('/api/folders').then(r => {
                        this.$store.commit('setFolders', r.data);
                        this.saving = false;
                        this.$store.commit('hideModal')
                        if (res.data.number !== this.project.number) {
                            this.$router.push(`/dossier/${encodeURIComponent(res.data.number)}`)
                        }
                    });

                }).catch(e => {
                    alert(e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message);
                    this.saving = false;
                })
            },
            setInfo(ev) {
                this.editedProject.status = ev.status;
                this.editedProject.id_geofoncier = ev.id_geofoncier;
                this.editedProject.number = ev.number;
            }
        },
        mounted(){
            axios.get('/api/users/manager').then(r => this.$store.commit('setManagers', r.data))
        },
        computed: {
            managers() {
                return this.$store.state.users.all.filter(u => u.role && (u.role.slug ==='admin' || u.role.slug ==='manager'))
            }
        },
        components: {
            NumberSelector,
            DatePicker,
        },
    }

</script>


<style scoped>
.text-info {
    font-size: 16px;
}
</style>
