<template>
    <div class="container py-1">
        <div class="form-group">
            <label class="control-label small"><strong>Factures</strong></label> <br/>
            <div class="upload-btn-wrapper">
                <button class="btn btn-sm text-info" @click="((addDocumentModal=true) && (addDocumentType='bills'))">
                    <i class="fas fa-plus"></i>
                    <span class="ml-1">Ajouter une facture</span>
                </button>
            </div>
            <div class="table-responsive">
                <table class="table table-borderedx table-striped">
                    <tbody>
                        <tr v-for="(bill, index) in documents.bills" :key="index">
                            <!-- DATE -->
                            <td v-if="!documentEdited || documentEdited !== bill">{{ bill.date.toDateString() }}</td>
                            <td v-if="documentEdited === bill">
                                <DatePicker
                                v-model="bill.date"
                                color="blue"
                            /></td>
                            <!-- NAME -->
                            <td v-if="!documentEdited || documentEdited !== bill"><strong>{{ bill.customName ? bill.customName : bill.name }}</strong><br />
                                <small v-if="bill.customName">{{ bill.name }}</small>
                            </td>
                            <td v-if="documentEdited === bill"><input class="form-control-sm" type="text" v-model="bill.customName" :placeholder="bill.name"></td>
                            <!-- BUTTON -->
                            <td v-if="!documentEdited" class="text-right"><i class="fa fa-pen" style="cursor:pointer;" @click="documentEdited = bill"></i></td>
                            <td v-if="documentEdited === bill" class="text-right"><i style="cursor:pointer;" class="fa fa-save" @click="saveDocument(bill)"></i></td>
                            <td class="text-center"><i style="cursor:pointer;" class="fas fa-trash-alt"  @click="deleteDocument(index, 'bills')"></i></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="form-group">
            <label class="control-label small"><strong>Devis / Contrats</strong></label> <br/>
            <div class="upload-btn-wrapper">
                <button class="btn btn-sm text-info" @click="((addDocumentModal=true) && (addDocumentType='contracts'))">
                    <i class="fas fa-plus"></i>
                    <span class="ml-1">Ajouter un devis / contrat</span>
                </button>
            </div>
            <div class="table-responsive">
                <table class="table table-borderedx table-striped">
                    <tbody>
                        <tr v-for="(contract, index) in documents.contracts" :key="index">
                            <!-- DATE -->
                            <td v-if="!documentEdited || documentEdited !== contract">{{ contract.date.toDateString() }}</td>
                            <td v-if="documentEdited === contract">
                                <DatePicker
                                v-model="contract.date"
                                color="blue"
                            /></td>
                            <!-- NAME -->
                            <td v-if="!documentEdited || documentEdited !== contract"><strong>{{ contract.customName ? contract.customName : contract.name }}</strong><br />
                                <small v-if="contract.customName">{{ contract.name }}</small>
                            </td>
                            <td v-if="documentEdited === contract"><input class="form-control-sm" type="text" v-model="contract.customName" :placeholder="contract.name"></td>
                            <!-- BUTTON -->
                            <td v-if="!documentEdited" class="text-right"><i class="fa fa-pen" style="cursor:pointer;" @click="documentEdited = contract"></i></td>
                            <td v-if="documentEdited === contract" class="text-right"><i class="fa fa-save" style="cursor:pointer;" @click="saveDocument(contract)"></i></td>
                            <td class="text-center"><i class="fas fa-trash-alt" style="cursor:pointer;"  @click="deleteDocument(index, 'contracts')"></i></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <AddDocument :project="project" :doctype="addDocumentType" v-if="addDocumentModal" @close="addDocumentModal=false" @addDocs="addDocs"></AddDocument>
    </div>
</template>
<script>
    import DatePicker from 'v-calendar/lib/components/date-picker.umd'
    import AddDocument from './modals/AddDocument.vue'

    export default {
        name : 'Documents',
        props : ['project'],
        data() {
            return {
                documentEdited : null,
                documents: {...this.project.documents},
                addDocumentModal: false,
                addDocumentType: 'bills'
            }
        },
        methods : {
            addDocs(docs) {
                const docsWithDate = docs.map(d => {
                    d.date = new Date();
                    return d;
                })
                if (this.addDocumentType === 'bills') {
                    this.documents.bills.push(...docsWithDate)
                } else {
                    this.documents.contracts.push(...docsWithDate)
                }

                console.log('adding docs', this.documents);

                this.$emit('setInfo', this.documents)
            },
            saveDocument(doc) {
                this.documentEdited = null
            },
            deleteDocument(index, type) {
                this.documents[type].splice(index, 1);
            }
        },
        mounted(){

        },
        computed: {

        },
        components: {
            DatePicker,
            AddDocument,
        },
        watch: {
            bills : function () {
                console.log(this.bills)
            }
        }

    }

</script>


<style scoped>
tr > td {
    padding: 5px;
}
.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}
</style>
