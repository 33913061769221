<template>
    <div class="container py-1">
        <div class="form-group">
            <p class="text-center p-4" v-if="!procedure || !procedure.steps">Aucune étape</p>

            <draggable v-model="procedure.steps" class="row" >
                <div class="card col-5 p-0 m-3" v-for="(step, i) in procedure.steps" :key="step.name+step.order">
                    <span class="pl-3 pt-2">Étape {{ i +1 }}</span>

                    <Step :step="step"></Step>
                </div>
            </draggable>

            <a class="btn btn-link text-info btn-sm" @click="addStep"><i class="fas fa-plus"></i> <span class="ml-1">Ajouter une étape</span></a> <br/>


        </div>
    </div>
</template>
<script>
    import draggable from 'vuedraggable'
    import Step from "./components/ProcedureStep.vue";

    export default {
        name : 'StepFolder',
        props : ['project'],
        data() {
            return {
                procedure: {...this.project.procedure},
                order: 10,
            }
        },
        methods: {
            addStep : function () {
                this.procedure.steps.push({
                    order: this.order,
                })
                this.order += 10;
            },
            deleteStep : function (i) {
                this.procedure.steps.splice(i, 1)
            },
        },
        beforeDestroy() {
            let order = 10;
            const steps = this.procedure.steps.map((s) => {
                s.order = order;
                order += 10;
                s.name = s.step_type.name;
                return s;
            });
            this.procedure.steps = steps;
            this.$emit('setInfo', this.procedure)
        },
        components: {
            Step,
            draggable,
        },
    }

</script>


<style scoped>

</style>
