<template>
    <div>
        <div class="modal-backdrop">
            <div class="modal" tabindex="-1" role="dialog" style="display: block" v-on:click.self="$store.commit('hideModal')">
                <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered" role="document">
                    <div class="modal-content" style="min-height:400px">
                        <div class="modal-header">
                            <h5 class="modal-title">Clients du dossier<br/>
                            </h5>
                            <button type="button" class="close" v-on:click="$store.commit('hideModal')" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body p-3">
                            <div class="form-group" v-if="user.role && (user.role.slug === 'admin' || user.role.slug === 'manager')">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label>Ajouter un contact client</label>
                                    </div>

                                    <div class="col-md-10">
                                        <v-select label="name" v-model="addedClient" :options="searchContacts" @search="contactsRequest" :disabled="saving">
                                            <template slot="option" slot-scope="option">
                                                <div class="d-center">
                                                    <span v-if="option.firstname">{{ option.firstname }}</span>
                                                    <span v-if="option.lastname">{{ option.lastname }}</span>
                                                    <span v-if="!option.lastname && !option.firstname">{{ option.name }}</span>
                                                    <small v-if="option.email">- {{ option.email }}</small>
                                                    <small v-if="option.role && option.role.name">
                                                        - {{option.role.name}}
                                                    </small>
                                                    <small v-if="option.company">
                                                        <strong>({{ option.company.name }})</strong>
                                                    </small>
                                                </div>
                                            </template>
                                            <template #no-options="{ search, searching, loading }">
                                                Entrez quelques caractères pour rechercher un contact
                                            </template>
                                        </v-select>
                                    </div>
                                    <div v-if="user.role && (user.role.slug === 'admin' || user.role.slug === 'manager')" class="col-md-2">
                                        <button :disabled="!this.addedClient" class="btn btn-primary float-right" @click="addClient">
                                            <i class="fas fa-spin fa-spinner" v-if="saving"></i>
                                            <i class="fas fa-plus" v-else></i>
                                            Ajouter</button>
                                    </div>
                                </div>
                            </div>
                            <table class="table table-borderedx table-striped">
                                <thead>
                                    <tr>
                                        <th class="small border-top-0">Nom</th>
                                        <th class="small border-top-0">Email</th>
                                        <th class="small border-top-0">Société</th>
                                        <th class="small border-top-0"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                <UserLine v-for="client in clients" :key="client.id" :user="client" :folder="folder" :canDelete="user && user.role && (user.role.slug === 'admin' || user.role.slug === 'manager') && clients.length > 1"></UserLine>
                                <tr v-if="!clients || clients.length === 0">
                                    <td colspan="4">
                                        <div class="text-center text-muted">
                                            Aucun client
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-light" @click="$store.commit('hideModal')">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END MODAL -->
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    import UserLine from './UserLine.vue';

    export default {
        name : 'ClientsModal',
        props: ['clients', 'folder'],
        data() {
            return {
                company: this.clients && this.clients.length > 0 ? this.clients[0].company : null,
                addClientModal: false,
                addedClient: null,
                saving: false,
                searchContacts: [],
                contactSearchCancelToken: null,
                lastSearch: null,
            }
        },
        methods: {
            contactsRequest(search, loading) {
                if (!search) {
                    return
                }
                if (this.lastSearch) {
                    clearTimeout(this.lastSearch);
                    this.lastSearch = null;
                }
                this.lastSearch = setTimeout(() => {
                    this.getContacts(search, loading)
                }, 500)
            },
            getContacts(search, loading) {
                if (!search) {
                    return
                }

                if (this.contactSearchCancelToken) {
                    this.contactSearchCancelToken.cancel()
                    this.contactSearchCancelToken = null;
                }
                const CancelToken = axios.CancelToken;
                this.contactSearchCancelToken = CancelToken.source();
                loading(true)
                axios.get('/api/contacts/search?s='+search, {
                    cancelToken: this.contactSearchCancelToken.token
                }).then(res => {
                    console.log("got contacts", res.data);
                    this.searchContacts = res.data;
                    loading(false)
                }).catch(() => {
                    loading(false)
                })
            },
            createdClient(val) {
                this.addedClient = val;
                this.addClient();
            },
            addClient() {
                this.saving = true
                const folder = {...this.folder}

                folder.contacts = {clients: [this.addedClient]};

                console.log('this.company', this.company)
                console.log('this.addedClient.company', this.addedClient.company)
                if (!this.company || !this.company.name) {
                    folder.client_type = 1;
                }

                axios.put(`/api/folders/${folder.id}`, folder).then(() => {
                    axios.get('/api/folders').then(r => this.$store.commit('setFolders', r.data)).then(() => {
                        this.saving = false
                        this.addedPartner = null;
                    }).catch(() => {
                        this.saving = false;
                        this.addedPartner = null;
                    });
                }).catch((e) => {
                    alert('Impossible d\'ajouter un contact client ' + e.message);
                    this.saving = false;
                    this.addedPartner = null;
                })
            }
        },
        mounted(){

        },
        computed: {
            user: function () {
                return this.$store.state.user.data
            },
            all_contacts() {
                //Remove existing clients from contacts
                const part = this.clients.map(p => p.id)
                const filtered = this.$store.state.contacts.items.filter(c => {
                    if (c.id) {
                        return part.indexOf(c.id) === -1 && this.user.id !== c.id;
                    }
                    return true
                }).filter((thing, index, self) => {
                    //remove contacts with duplicate ids
                    if (!thing.id) {
                        return true;
                    }
                    return index === self.findIndex((t) => (
                        t.id === thing.id
                    ))
                })

                console.log('filtered', JSON.parse(JSON.stringify(filtered)))
                return [...new Set(filtered)]
            },
        },
        components: {
            UserLine,
        },
    }

</script>


<style scoped>
    .text-info {
        font-size: 16px;
    }
    .v-select {
        position:relative !important;
    }
    ul[role="listbox"], .vs__dropdown-menu, .v-select ul * {
        z-index:10000 !important;
        position:relative !important;
    }
</style>
