<template>
    <nav class="navbar navbar-expand-lg navbar-light bg-light border-top border-bottom">
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav">
                <!-- <li class="nav-item">
                    <a class="nav-link text-uppercase px-4" style="cursor:pointer;" @click="$router.push('/compte')">Mon compte</a>
                </li> -->
                <li class="nav-item">
                    <router-link class="nav-link text-uppercase px-4" style="cursor:pointer;" to="/dossiers">Tous les dossiers</router-link>
                </li>
                <li class="nav-item" v-if="$store.state.user.data && $store.state.user.data.role && $store.state.user.data.role.slug === 'client'">
                    <router-link class="nav-link text-uppercase px-4" style="cursor:pointer;" to="/contrats">Contrats & factures</router-link>
                </li>
                <li class="nav-item" v-if="$store.state.user.data && $store.state.user.data.role && $store.state.user.data.role.slug === 'admin'">
                    <router-link class="nav-link text-uppercase px-4" style="cursor:pointer;" to="/utilisateurs">Gestion utilisateurs</router-link>
                </li>

            </ul>
        </div>
    </nav>
</template>
<script>
    export default {
        data() {
            return {
            }
        },
        mounted(){
            console.log('user', this.$store.state.user)
        },
        computed: {
            
        },
        components: {
        },
    }

</script>


<style scoped>
    .router-link-active {
        font-weight: bold;
    }
</style>