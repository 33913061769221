import { render, staticRenderFns } from "./ViewDocuments.vue?vue&type=template&id=6bc24616&scoped=true&"
import script from "./ViewDocuments.vue?vue&type=script&lang=js&"
export * from "./ViewDocuments.vue?vue&type=script&lang=js&"
import style0 from "./ViewDocuments.vue?vue&type=style&index=0&id=6bc24616&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6bc24616",
  null
  
)

export default component.exports