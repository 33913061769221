<template>
    <div class="container py-1">
        <div class="form-group">
            <label class="control-label small"><strong>Numéro de dossier *</strong></label>
            <NumberSelector :project="info" @setProjectInfo="setInfo"></NumberSelector>
        </div>

        <div class="form-group">
            <label class="control-label small"><strong>Nom du dossier</strong></label>
            <input type="text" class="form-control"  v-model="info.name">
        </div>
        <div class="form-group">
            <label class="control-label small"><strong>Géomètre expert *</strong></label>
            <v-select label="name" :options="geometres"  v-model="info.manager"></v-select>
        </div>
        <div class="form-group">
            <label class="control-label small"><strong>Responsable du dossier</strong></label>
            <v-select label="name" :options="responsables"  v-model="info.technician"></v-select>
        </div>
        <div class="form-group">
            <label class="control-label small"><strong>Statut *</strong></label>
            <v-select label="name" :options="$store.state.status.items"  v-model="info.status"></v-select>
        </div>
        <div class="form-group">
            <label class="control-label small"><strong>Date de livraison prévue</strong></label>
            <DatePicker format="dd/MM/yyyy" :language="fr" v-model="info.date" name="date" input-class="form-control" :typeable="true"></DatePicker>

        </div>
    </div>
</template>
<script>
    import axios from 'axios'

    import DatePicker from '@sum.cumo/vue-datepicker'
    import '@sum.cumo/vue-datepicker/dist/vuejs-datepicker.css'
    import {fr} from '@sum.cumo/vue-datepicker/dist/locale'

    import NumberSelector from './components/NumberSelector.vue'

    const experts = [
        'simon.bazalgette@valoris.expert',
        'christophe.jalbaud@valoris.expert',
        'etienne.saint-aubin@valoris.expert',
        'bertrand.warin@valoris.expert',
        'jean-odon.cenac@valoris.expert',
        'virginie.jeansou@valoris.expert',
        'jean-baptiste.tremblin@valoris.expert',
        'gabriel.blanchet@valoris.expert',
    ].map(n => n.toLowerCase())

    export default {
        props : ['project'],
        data() {
            return {
                info: {...this.project},
                fr,
            }
        },
        beforeDestroy() {
            this.$emit('setInfo', this.info)
        },
        mounted(){
            axios.get('/api/users').then(r => this.$store.commit('setUsers', r.data))
        },
        methods: {
            setInfo(ev) {
                console.log('infog set info', ev)
                this.info.status = ev.status;
                this.info.id_geofoncier = ev.id_geofoncier;
                this.info.number = ev.number;
                this.info.procedure = ev.procedure;
                this.info.name = ev.name;

                this.info.manager = ev.manager;
                this.info.manager_id = ev.manager_id;
                this.$emit('setInfo', this.info)
            }
        },
        computed: {
            geometres() {
                return this.$store.state.users.all.filter(u => (u.role.slug === 'admin' || u.role.slug === 'manager')
                    && experts.indexOf(u.email.toLowerCase()) >= 0)
                    .sort((a, b) => a.email.localeCompare(b.email))
            },
            responsables() {
                return this.$store.state.users.all.filter(u => (u.role.slug === 'admin' || u.role.slug === 'manager')
                    && experts.indexOf(u.email.toLowerCase()) === -1)
                    .sort((a, b) => a.email.localeCompare(b.email))
            }
        },
        components: {
            NumberSelector,
            DatePicker,
        },
    }

</script>


<style scoped>

</style>
