<template>
    <div id="toastsContainerTopRight" class="toasts-top-left fixed m-5">
        <div class="toast bg-success fade show" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header">
                <strong class="mr-auto">
                    {{ title }}
                </strong>
            </div>
            <div class="toast-body">
                {{ body }}
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['title', 'body']
}
</script>
