import axios from 'axios';

export default {
    state: { all: [], managers: [] },
    mutations: {
        setUsers (state, items) {
            console.log('all users', items)
            state.all = items;
        },
        setManagers (state, items) {
            console.log('manager users', items)
            state.managers = items;
        }
    },
    actions: {
        saveUser({commit, state}, user) {
            let req;
            if (user.id) {
                req = axios.put(`/api/users/${user.id}`, user)
                req.then(res => {
                    const newUsers = state.all.map(u => {
                        if (u.email === res.data.email) {
                            return res.data;
                        }
                        return u;
                    })
                    commit('setUsers', newUsers);
                })
            } else {
                console.log('new_user');
                req = axios.post(`/api/users`, user)
                req.then(res => {
                    const newUsers = state.all.map(u => {
                        if (u.email === res.data.email) {
                            return res.data;
                        }
                        return u;
                    })
                    commit('setUsers', newUsers);
                })
                req.catch(error => {
                    if (error.response.data.errors.email){
                        alert(error.response.data.errors.email[0]);
                    }
                });
            }

            return req;
        },
        deleteUser({commit, state}, user) {
            const req = axios.delete(`/api/users/${user.id}`, user)
            const newUsers = state.all.filter(u => u.id !== user.id)
            req.then(() => {
                commit('setUsers', newUsers);
            })

            return req;
        }
    }
};
