
export default {
    state: { items: [
        {
            id : 1,
            slug: 'planned',
            name : "Prévu",
        },
        {
            id : 2,
            slug: 'ongoing',
            name : "En cours",
        },
        {
            id : 3,
            slug: 'done',
            name : "Terminé",
        },
    ] },
};