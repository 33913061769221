<template>
    <tr style="cursor: pointer;">
        <td  @click.prevent="$router.push(`/dossier/${encodeURIComponent(folder.number)}`)">
            <p class="mb-1" style="line-height:1.2;">
                <b>{{ folder.name }}</b><br>
                <span v-if="folder.client_type === 1">
                    <span  v-for="client in folder.clients" :key="client.id">{{ client.name }} </span>
                </span>
                <span v-else-if="folder.clients.length > 0 && folder.clients[0].company">
                    <span>{{ folder.clients[0].company.name }}</span>
                </span>
            </p>
            N° de dossier : {{ folder.number }} <br />
            <!-- TO DO : BADGE -->
            <!-- <span class="badge badge-success text-white">
                Le client a finalisé la création du compte
            </span><br/>
            <span class="badge badge-danger text-white">
                1 document et 1 commentaire ont été mis en ligne par le client à l'étape Topo.
            </span> -->
        </td>
        <td @click.prevent="$router.push(`/dossier/${encodeURIComponent(folder.number)}`)">
            <div>
                {{ folder.manager ? folder.manager.name : 'Pas de gestionnaire' }}<br>
                <!--{{ folder.procedure.name }}-->
            </div>
        </td>
        <td @click.prevent="$router.push(`/dossier/${encodeURIComponent(folder.number)}`)">
            <p class="text-center w-100 mb-1">{{ folder.status.name }}</p>
            <div style="margin:0 auto">
                <Step :steps="folder.procedure ? folder.procedure.steps : []"></Step>
            </div>
        </td>
        <td v-if="user && user.role && user.role === 'admin'">
            <button type="button" class="btn btn-danger" @click.prevent="deleteFolder">
                <i class="fas fa-spin fa-spinner" v-if="deleting"></i>
                <i class="fas fa-trash" v-else></i>
            </button>
        </td>
    </tr>
</template>
<script>
    import Step from './Step.vue'
    export default {
        name: 'FolderRow',
        props: ['folder'],
        data() {
            return {
                deleting: false,
            }
        },
        methods: {
            deleteFolder() {
                if (confirm(`Le dossier ${this.folder.name} va être supprimé. Cette action est irréversible. Souhaitez-vous continuer ?`)) {
                    this.deleting=true;

                    this.$store.dispatch('deleteFolder', this.folder).then(() => {
                        this.deleting = false;
                    }).catch(() => {
                        this.deleting = false;
                    })
                }
            },
        },
        computed: {
            user() {
                return this.$store.state.user.data;
            },
        },
        components: {
            Step,
        }
    }
</script>