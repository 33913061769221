<template>
    <div style="height: 500px; width: 100%">
        <l-map ref="myMap"
           :center="center"
           :min-zoom="2"
           :zoom="16"
           @update:bounds="boundsUpdated"
        >
            <!-- url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}" -->

            <l-tile-layer
            url="https://data.geopf.fr/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/jpeg&LAYER=ORTHOIMAGERY.ORTHOPHOTOS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}"
            attribution='<a target="_blank" href="https://www.geoportail.gouv.fr/">Geoportail France</a>'
            :tileSize="256">
            </l-tile-layer>

            <l-wms-tile-layer
                    v-for="cadastre in cadastres"
                    :key="cadastre.id"
                    :baseUrl="cadastre.url"
                    layers="AMORCES_CAD,BU.Building,CP.CadastralParcel,HYDRO"
                    format="image/png"
                    :transparent="true"
                    version="1.3.0"
            ></l-wms-tile-layer>

            <l-wms-tile-layer
                    :key="geokey"
                    :baseUrl="geofoncier"
                    layers="DOSSIERS_LOCALISANTS"
                    format="image/png"
                    :transparent="true"
            ></l-wms-tile-layer>
            <l-marker
                    v-if="marker"
                    :lat-lng="marker"
                    :icon="icon"
            >
                <l-tooltip>
                <div>Dossier : {{project.name}} ({{project.number}})</div>
                </l-tooltip>
            </l-marker>


        </l-map>

    </div>
</template>
<script>
    //https://www.geofoncier.fr/apipci/communes/intersects
    import { latLng } from "leaflet";
    import { LMarker, LIcon, LTooltip } from 'vue2-leaflet';
    import axios from 'axios';

    import proj4 from 'proj4';

    proj4.defs("EPSG:2154","+proj=lcc +lat_1=49 +lat_2=44 +lat_0=46.5 +lon_0=3 +x_0=700000 +y_0=6600000 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs");

    export default {
        components: {
            LTooltip,
        },
        name: 'Map',
        props: ['project'],
        data() {
            return {
                zoom: 10,
                center: latLng(43.60426, 1.44367),
                attribution:'&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
                geofoncier: `https://api2.geofoncier.fr/api/referentielsoge/wxs?ENR_CAB_DETENTEUR=2010C200002&TYPE_FILTRE=du_cabinet`,
                marker: latLng(43.60426, 1.44367),
                fillColor: "#e4ce7f",
                cadastres: [],
                geokey:20,
            }
        },
        watch: {
            project() {
                this.getProjectMarker()
            }
        },
        mounted() {
            this.getProjectMarker()
        },
        methods: {
            boundsUpdated(e) {
                axios.post('/api/geo/intersect', e).then(r => {
                    if (r.data.total && r.data.records && r.data.total < 10) {
                        let id = 10;
                        this.cadastres = r.data.records.map(ins => {
                            console.log('id', id)
                            const url = `https://inspire.cadastre.gouv.fr/scpc/${ins.insee}.wms?crs=EPSG:3857`;
                            id++
                            return {id, url,}
                        });
                        this.geokey = id+10;
                    } else {
                        this.cadastres = [];
                    }

                }).catch(e => {
                    console.log(e.message, e)
                })
            },
            getProjectMarker() {
                if (this.project.lat && this.project.lng) {
                    this.marker = latLng(this.project.lat, this.project.lng)
                    this.center = latLng(this.project.lat, this.project.lng);
                    console.log('point', this.marker)
                } else {
                    axios.get(`/api/geo/project/${this.project.id_geofoncier}`).then(r => {
                        this.marker = r.data.localisant;
                        console.log('project data', r.data)
                        console.log('project marker', r.data.localisant)
                        // Update on server with city, longitude and latitude
                        if (r.data.localisant && r.data.localisant.features && r.data.localisant.features.length ) {
                            console.log('geom', r.data.localisant.features[0].geometry.coordinates)
                            const n = [r.data.localisant.features[0].geometry.coordinates[0], r.data.localisant.features[0].geometry.coordinates[1]]
                            //const n = proj4('EPSG:2154','WGS84', [r.data.localisant.features[0].geometry.coordinates[0], r.data.localisant.features[0].geometry.coordinates[1]]);
                            console.log('point', n);
                            // TODO Update on server with city, longitude and latitude
                            const p = {...this.project}

                            p.lat = n[1]+'';
                            p.lng = n[0]+'';

                            axios.put(`/api/folders/${this.project.id}`, p).then(() => {
                                // Reload all folders from server
                                axios.get('/api/folders').then(res => {
                                    this.$store.commit('setFolders', res.data)
                                });
                            })
                        }

                    }).catch(e => {
                        console.log(e.message, e)
                    });
                }

            },
        },
        computed: {
            styleFunction() {
                const fillColor = this.fillColor;
                return () => {
                    return {
                        weight: 2,
                        color: "#ECEFF1",
                        opacity: 1,
                        fillColor: fillColor,
                        fillOpacity: 1
                    };
                };
            },
            icon() {
                return new L.Icon({
                    iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
                    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/0.7.7/images/marker-shadow.png',
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                    popupAnchor: [1, -34],
                    shadowSize: [41, 41]
                })
            },
        },
    }
</script>
