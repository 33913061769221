const mapChildren = (i, path, items) => {
    if (i.path === path) {
        i.children = items;
    } else if (i.children && i.children.length) {
        i.children = i.children.map((c) => {
            return mapChildren(c, path, items)
        })
    }
    return i;
}

const setSelected = (i, item) => {
    //i.selected = false
    if (i.path === item.path) {
        i.selected = !i.selected;
    } else if (i.children && i.children.length) {
        i.children = i.children.map((c) => {
            return setSelected(c, item)
        })
    }
    return {...i};
}

const clearSelected = (i) => {
    i.selected = false
    if (i.children && i.children.length) {
        i.children = i.children.map((c) => {
            return clearSelected(c)
        })
    }
    return {...i};
}

const findItem = (path, items) => {
    if (!items) {
        return [];
    }
    return items.reduce((a, item) => {
        if (a) return a;
        if (path === item.path) return item;
        if (item.children) return findItem(path, item.children);
    }, null);
}

const findSelected = (items) => {

    let selected = [];

    items.forEach((item) => {
        let stack = [], node, ii;
        stack.push(item);
        while (stack.length > 0) {
            node = stack.pop();
            if (node.selected) {
                selected.push(node)
            } else if (node.children && node.children.length) {
                for (ii = 0; ii < node.children.length; ii += 1) {
                    stack.push(node.children[ii]);
                }
            }
        }
    });

    return selected;
}

export default {
    state: { items: [], search:null },
    mutations: {
        setFS (state, items) {
            state.items = items;
        },
        addChildren (state, {path, items}) {
            state.items = state.items.map((i) => {
                return mapChildren(i, path, items)
            });
        },
        setSelected(state, item) {
            state.items = state.items.map(i => {
                return setSelected(i, item);
            })
        },
        clearSelected(state) {
            state.items = state.items.map(i => {
                return clearSelected(i);
            })
        },
        setSearch(state, s) {
            state.search = s;
        }
    },
    getters: {
        selectedFiles: state => {
            return findSelected(state.items)
        },
        search: (state) => {
            console.log('state.search',state.search)
            return findItem(state.search, state.items)
        }
    }
};
