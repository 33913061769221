<template>

    <div class="content py-5" v-if="currentFolder">
        <div class="container">
            <div class="row">
                <!-- DETAIL DOSSIER -->
                <div class="col-md-8">
                    <div class="mb-3">
                        <h4>
                            Dossier : <b>{{ currentFolder.name }}</b><br>
                            Client :
                            <b v-if="currentFolder.client_type === 1">
                                <span v-for="client in currentFolder.clients" :key="client.id">{{ client.name }} </span>
                            </b>
                            <b v-else-if="currentFolder.clients.length > 0 && currentFolder.clients[0].company">
                                <span>{{ currentFolder.clients[0].company.name }}</span>
                            </b>
                        </h4>
                        <!-- TO DO -->
                        <p>
                            <!--Localisation site : <b>12 avenue de la Gare, 31 000 Toulouse</b> <br />-->
                            Numéro de dossier : <b>{{ currentFolder.number }}</b><br/>
                            Géométre Expert : <span v-if="currentFolder.manager"><b>{{ currentFolder.manager.name }}</b> - <a
                            :href="`mailto:${currentFolder.manager.email}`">{{ currentFolder.manager.email }}</a></span>
                            <span v-else>Aucun gestionnaire</span>
                            <br/>

                            <span v-if="currentFolder.technician">Responsable du dossier : <b>{{
                                    currentFolder.technician.name
                                }}</b> - <a :href="`mailto:${currentFolder.technician.email}`">{{
                                    currentFolder.technician.email
                                }}</a><span v-if="currentFolder.technician.phone"> - <a
                                :href="`tel:${currentFolder.technician.phone}`">{{ currentFolder.technician.phone }}</a></span><br/></span>

                            Statut : <b> {{ currentFolder.status.name }}</b>

                        </p>
                        <button v-if="user && user.role && (user.role.slug === 'admin' || user.role.slug === 'manager')"
                                class="btn-sm btn btn-link text-muted pl-0"
                                @click="$store.commit('showModal', 'editDetails')">
                            <i class="fas fa-edit"></i> Modifier
                        </button>
                        <button
                            v-if="user && user.role && (user.role.slug === 'admin' || user.role.slug === 'manager') && currentFolder.id_geofoncier"
                            class="btn-sm btn btn-link text-muted pl-0" @click="sync">
                            <i class="fas fa-sync fa-spin" v-if="syncing"></i>
                            <i class="fas fa-sync" v-else></i>
                            Synchroniser
                        </button>
                        <button v-if="user && user.role && user.role.slug === 'admin'"
                                class="btn-sm btn btn-link text-danger pl-0" @click="deleteFolder">
                            <i class="fas fa-trash"></i> Supprimer
                        </button>
                    </div>
                </div>
                <!-- BUTTON DOSSIER -->
                <div class="col-md-4"
                     v-if="isClient || (user.role && user.role.slug && (user.role.slug === 'admin' || user.role.slug === 'manager'))">
                    <div class="mb-3 text-right">
                        <button type="button" class="btn btn-primary" @click="$store.commit('showModal', 'contracts')">{{
                                'Voir le bon de commande'
                            }}
                        </button>
                    </div>
                    <div class="mb-3 text-right">
                        <button type="button" class="btn btn-primary" @click="$store.commit('showModal', 'bills')">Voir les
                            factures
                        </button>
                    </div>
                </div>
            </div>
            <!-- DETAIL UTILISATEURS AVEC DETAIL ET BOUTON D'ACTIVATION EN MODAL-->
            <div class="border-top border-bottom py-2 row">
                <div class="col-md-8">
                    <a class="text-info" style="cursor:pointer;"
                       @click="$store.commit('showModal', 'viewClients')">{{ currentFolder.clients.length }}
                        Contact{{ currentFolder.clients.length > 1 ? 's' : '' }} client</a>
                    <!-- <a class="text-info" style="cursor:pointer;" @click="$store.commit('showModal', 'viewPartners')">{{ currentFolder.partners.length }} Contact{{currentFolder.partners.length > 1 ? 's': ''}} partenaires</a> -->
                </div>
                <div class="col-md-4">
                    <div class="float-right">
                        Date de livraison prévue : <b> {{
                            currentFolder.date ? (new Date(currentFolder.date)).toLocaleDateString('fr') : 'Non renseignée'
                        }}</b>
                    </div>

                </div>
            </div>
            <div class="row my-3">
                <div class="col-md-8">
                    <a
                        v-if="user.role && (user.role.slug === 'admin' || user.role.slug === 'manager') && currentFolder && currentFolder.procedure"
                        class="pl-3 text-info"
                        style="cursor:pointer;"
                        @click="addStep"
                    >
                        <i class="fa fa-plus"></i>
                        Ajouter une étape
                    </a>
                </div>
                <!-- STEPS WITH HOVER DETAIL -->
                <div class="col-md-4">
                    <!-- <Step :steps="procedure"></Step> -->
                    <p class="text-info text-right mb-0" v-if="stepDocs">
                        <a class="btn btn-outline-infox btn-sm p-0"
                           :href="`/api/folders/${currentFolder.id}/step_documents.zip`"><i class="fas fa-download"></i>
                            <span class="ml-1">Télécharger tous les documents du dossier</span></a>
                    </p>
                </div>
            </div>
            <div id="accordion">
                <!-- BEGIN CARD -->
                <div
                    v-if="currentFolder && currentFolder.procedure && currentFolder.procedure.steps && currentFolder.procedure.steps.length">
                    <draggable v-model="stepList" handle=".handle" v-if="user && user.role && (user.role.slug === 'manager' || user.role.slug === 'admin')">
                        <StepDetails
                            v-for="(step, i) in currentFolder.procedure.steps"
                            :key="step.id"
                            :step="step"
                            :i="i"
                            :folder="currentFolder"
                        >
                        </StepDetails>
                    </draggable>
                    <StepDetails
                        v-else
                        v-for="(step, i) in currentFolder.procedure.steps"
                        :key="step.id"
                        :step="step"
                        :i="i"
                        :folder="currentFolder"
                    >
                    </StepDetails>
                </div>


                <div v-else class="text-center text-muted m-4">
                    <h3 class="mb-4">Aucune étape</h3>
                </div>
            </div>

            <div class="row" v-if="currentFolder.id_geofoncier">
                <Map :project="currentFolder"></Map>
            </div>
        </div>
        <ViewDocuments :type="'contracts'" :documents="contracts" v-if="modal === 'contracts'"
                       :project="currentFolder"></ViewDocuments>
        <ViewDocuments :type="'bills'" :documents="bills" v-if="modal === 'bills'"
                       :project="currentFolder"></ViewDocuments>
        <ViewClients :folder="currentFolder" :clients="currentFolder.clients"
                     v-if="modal === 'viewClients'"></ViewClients><!--  :procedure="procedure" -->
        <!-- <ViewPartners :folder="currentFolder" :partners="currentFolder.partners" v-if="modal === 'viewPartners'"></ViewPartners> -->
        <EditDetails :project="currentFolder" v-if="modal === 'editDetails'"></EditDetails>
    </div>
</template>
<script>
import axios from 'axios';
import draggable from 'vuedraggable'

import Menu from '../../Menu.vue';
import Step from '../Step.vue';

import Map from './Map.vue';
import StepDetails from './StepDetails';
import ViewDocuments from './modals/ViewDocuments.vue';
import ViewClients from './modals/ViewClients.vue';
// import ViewPartners from './modals/ViewPartners.vue';
import EditDetails from './modals/EditDetails.vue';


export default {
    name: 'SingleFolder',
    data() {
        return {
            accesspartner: null,
            modal: null,
            modalData: null,
            status: this.$store.state.stepStatuses.items,
            procedure: null,
            syncing: false,
        }
    },
    methods: {
        saveProcedure(proc) {
            this.procedure = proc
        },
        sync() {
            this.syncing = true;
            const folder = {...this.currentFolder};

            if (!folder.id_geofoncier) {
                this.syncing = false;
                alert("Ce dossier ne provient pas de geofoncier.")
                return;
            }

            axios.get(`/api/geo/project/${folder.id_geofoncier}`).then(r => {
                console.log('folder details', r.data)
                const st = r.data.enr_statut;
                // find status.
                folder.status = this.$store.state.status.items.find(s => s.name.toUpperCase() === st.toUpperCase());
                folder.number = r.data.enr_ref_dossier;
                folder.id_geofoncier = r.data.id;

                axios.get(`/api/geo/ge/${r.data.enr_ge_createur}`).then(ge => {
                    if (ge.data.length) {
                        const geoUser = ge.data[0]
                        console.log('geo user', geoUser)
                        console.log('all users', this.$store.state.users.all)
                        const user = this.$store.state.users.all.find(u => u.firstname && u.lastname && u.firstname.toUpperCase() === geoUser.prenom_ge.toUpperCase() && u.lastname.toUpperCase() === geoUser.nom_ge.toUpperCase() && u.isActive)

                        if (typeof user !== 'undefined') {
                            folder.manager = user;
                            folder.manager_id = user.id;
                        }
                        this.$emit('setProjectInfo', this.info)
                    }
                })
                // TODO set procedure from here
                // geofoncier operations are in r.data.op_code;
                // op_code	[ "Eb", "Ec" ]

                let order = 0;

                const existingStepTypes = folder.procedure ? folder.procedure.steps.map(s => s.step_type_id) : [];

                const steps = r.data.op_code.map((op) => {
                    const stepType = this.$store.state.stepTypes.items.find(st => {
                        return st.code.toUpperCase() === op.toUpperCase()
                    })

                    order += 10;
                    return {
                        step_type_id: stepType.id,
                        name: stepType.name,
                        step_type: stepType,
                        status: 'planned',
                        order,
                        documents: [],
                    }
                }).filter(s => {
                    // Remove new step if it already exists in the procedure
                    return existingStepTypes.indexOf(s.step_type_id) === -1;
                })

                folder.procedure.steps = [...folder.procedure.steps, ...steps];

                axios.put(`/api/folders/${folder.id}`, folder).then((res) => {
                    // Reload all folders from server
                    axios.get('/api/folders').then(r => {
                        this.$store.commit('setFolders', r.data)
                        this.syncing = false;
                    });
                }).catch(e => {
                    this.syncing = false;
                    alert(e.message)
                })


                console.log('set procedure from geofoncier', folder.procedure);
            }).catch(e => {
                this.syncing = false;
                console.log(e.message, e)
            })


        },
        deleteFolder() {
            if (confirm(`Vous allez supprimer le dossier N°${this.currentFolder.number}. Cette opération est irréversible. (Les documents sur NextCloud ne seront pas affectés). Souhaitez-vous continuer ?`)) {
                axios.delete(`/api/folders/${this.currentFolder.id}`).then(() => {
                    axios.get('/api/folders').then(r => this.$store.commit('setFolders', r.data));
                    this.$router.push('/dossiers')
                }).catch(() => {
                    alert('Désolé, la suppression a échoué.')
                })
            }
        },
        addStep: function () {
            const procedure = this.currentFolder && this.currentFolder.procedure
                ? {...this.currentFolder.procedure}
                : null

            const stepType = this.$store.state.stepTypes.items ? this.$store.state.stepTypes.items[0] : null;
            if (procedure) {
                this.$store.state.stepStatuses.items.find(s => s.slug === 'planned')

                procedure.steps.unshift({name: 'Nouvelle étape', order: 0, documents: [], status: 'planned', step_type_id: stepType.id})
                this.stepList = [...procedure.steps];
            } else {
                // Create a procedure for this folder
                // Save folder
                const folder = {...this.currentFolder}
                const procedure = {
                    name: "Nouvelle procédure",
                    steps: [{name: 'Nouvelle étape', order: 1000, documents: [], status: 'planned', step_type_id: stepType.id}]
                }
                this.currentFolder.procedure = procedure;
                folder.procedure = procedure;
                axios.put(`/api/folders/${folder.id}`, folder).then(() => {
                    axios.get('/api/folders').then(r => this.$store.commit('setFolders', r.data));
                }).catch(e => {
                    alert(e.response && e.response.data && e.response.data.message ? e.response.data.message : e.message);
                })
            }
        },
    },
    mounted() {
        this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'showModal' || mutation.type === 'hideModal') {
                this.modal = state.modals.shown;
                this.modalData = state.modals.data;
            }
        });
    },
    computed: {
        currentFolder() {
            const cf = this.$store.state.folders.items ? this.$store.state.folders.items.find(f => f.number === decodeURIComponent(this.$router.currentRoute.params.number)) : null;
            if (cf) {
                // Save last viewed folder
                axios.post(`/api/users/me/lastfolder/${cf.id}`, [])
            }

            return cf;
        },
        contracts() {
            return this.currentFolder.documents.filter(d => d.document_type.slug === 'contract')
        },
        bills() {
            return this.currentFolder.documents.filter(d => d.document_type.slug === 'bill')
        },
        user: function () {
            return this.$store.state.user.data
        },
        isClient() {
            const fc = this.currentFolder.clients.find(c => c.id === this.user.id)
            return this.user && fc && fc;
        },
        stepDocs() {
            return this.currentFolder && this.currentFolder.procedure ? this.currentFolder.procedure.steps.map(s => s.documents.length).reduce((prev, cur) => {
                return prev + cur
            }, 0) : 0;
        },
        stepList: {
            get() {
                return this.currentFolder && this.currentFolder.procedure ? this.currentFolder.procedure.steps : []
            },
            set(newlist) {
                if (this.currentFolder.procedure) {
                    let order = 10;
                    this.currentFolder.procedure.steps = newlist.map(s => {
                        s.order = order;
                        order += 10;
                        return s;
                    })

                    this.$store.dispatch('updateProcedureSteps', {
                        steps: this.currentFolder.procedure.steps,
                        folder_id: this.currentFolder.id
                    })
                }
            }
        }
    },
    components: {
        Menu,
        Step,
        ViewDocuments,
        ViewClients,
        // ViewPartners,
        Map,
        StepDetails,
        EditDetails,
        draggable,
    },
}

</script>
