<template>
    <div class="modal-backdrop">
        <div class="modal" tabindex="-1" role="dialog" style="display: block" v-on:click.self.prevent="$emit('close')">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Vous avez oublié votre mot de passe.</h5>
                        <button type="button" class="close" v-on:click.self.prevent="$emit('close')" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body p-3">
                        <form @submit.prevent="send">
                            <div class="form-row">
                                <div class="form-group col-md-3">
                                    <label class="control-label small" :class="{ 'text-danger': submitted && $v.email.$error }"><b>E-mail :</b></label>
                                </div>
                                <div class="form-group col-md-7">
                                    <input type="text" class="form-control" v-model="email" :class="{ 'is-invalid': submitted && $v.email.$error }">
                                    <small class="text-danger" v-if="$v.email.$error">E-mail obligatoire</small>
                                </div>
                                <p>Vous allez recevoir un mail à cette adresse afin de réinitialiser votre mot de passe.</p>
                            </div>
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click.self.prevent="$emit('close')">Annuler</button>
                        <button type="button" class="btn btn-primary" v-on:click="send">
                            <i class="fa fa-spinner fa-spin" v-if="saving"></i>
                            Valider
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { required, email } from 'vuelidate/lib/validators'
    import Vuelidate from 'vuelidate'
    import axios from "axios";

    export default {
        props: ['user'],
        name: 'EditUserModal',
        data() {
            return {
                email : null,
                saving : false,
                submitted : false,
            }
        },
        validations: {
            email: { required, email },
        },
        methods: {
            async send () {
                this.saving = true;
                this.submitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.saving = false
                    return
                }
                axios.post('/api/forgetpwd', {email: this.email})
                .then(res => {
                    console.log(res);
                    if (res.data !== ''){
                        this.$emit('close');
                        alert('Un message a été envoyé sur votre adresse mail.')
                    }
                    else {
                        alert("Nous n'avons pas trouvé votre adresse mail")
                    }
                    this.saving = false;
                    this.submitted = false;
                }).catch(e => {
                    this.saving = false;
                    this.submitted = false;
                    alert('Impossible de vous envoyer un message de réinitialisation de mot de passe, désolé.')
                })

            },
        },
    }
</script>
