
export default {
    state: { shown: null, data: null },
    mutations: {
        showModal (state, mod) {
            if (typeof mod === 'object') {
                state.shown = mod.modal;
                state.data = mod.data;
            } else {
                state.shown = mod;
                state.data = null;
            }

        },
        hideModal (state) {
            console.log('hide modal');
            state.shown = null;
        }
    }
};