import axios from 'axios'

export default {
    state: { data: null },
    mutations: {
        setUser (state, user) {
            state.data = user;
        },
    },
    actions: {
        getUser({ commit }) {
            const req = axios.get('/api/users/me');

            req.then(r => {
                commit('setUser', r.data)

                if (r.data.role.slug === 'admin' || r.data.role.slug === 'manager') {
                    // SET mail templates
                    axios.get('/api/templates')
                        .then(s => {
                            commit('setMailTemplates', s.data);
                        }).catch(err => {
                        console.log('could not get mail templates', err)
                    })
                    axios.get(`/api/fs/ls/`).then(f => {
                        commit('setFS', f.data)
                        // year level
                        f.data.forEach(d => {
                            const currentPath = d.path.replace('remote.php/webdav/' + window.WEBDAV_ROOT, '')
                            if (currentPath !== 'DOSSIERS') {
                                // ignore evertything else
                                return;
                            }
                            axios.get(`/api/fs/ls/${currentPath}`).then(r => {
                                commit('addChildren', {
                                    path: d.path,
                                    items: r.data
                                })
                                r.data.forEach(v => {
                                    const currentPath2 = v.path.replace('remote.php/webdav/' + window.WEBDAV_ROOT, '')
                                    if (currentPath2.indexOf("DOSSIERS/20") === -1) {
                                        // ignore non dates files and folders
                                        return;
                                    }
                                    axios.get(`/api/fs/ls/${currentPath2}`).then(re => {
                                        commit('addChildren', {
                                            path: v.path,
                                            items: re.data
                                        })
                                    }).catch(err => {
                                        alert('Nextcloud est actuellement indisponible. ' + err.message)
                                    })
                                })
                            }).catch(err => {
                                alert('Nextcloud est actuellement indisponible. ' + err.message)
                            })
                        })
                    }).catch(err => {
                        alert('Nextcloud est actuellement indisponible. ' + err.message)
                    })
                }

                // SET FOLDERS
                axios.get('/api/folders').then(f => {
                    commit('setFolders', f.data);
                }).catch(err => {
                    alert('Impossible d\'obtenir les dossiers. ' + err.message)
                })

                // SET DOCUMENT TYPES
                axios.get('/api/document_types').then(d => {
                    commit('setDocumentTypes', d.data);
                }).catch(err => {
                    alert('Impossible d\'obtenir les types de documents. ' + err.message)
                })

                // SET STEP TYPES
                axios.get('/api/step_types')
                .then(s => {
                    commit('setStepTypes', s.data);
                }).catch(err => {
                    console.log('could not get types', err)
                })
            }).catch(err => {
                console.log('could not get user', err)
            })

            return req;
        },
        logout({commit}) {
            axios.get('/logout').then(r => {
                commit('setUser', null)
            })
        }
    }
};
