<template>

<div class='progress'>
  <div class='progress_inner'>

        <!-- <div class='progress_inner__step' data-toggle="tooltip" data-placement="top" 
        :title="`${step.name} : ${step.status.name ? step.status.name : step.status}`"
        :class="(step.status.id === 2 && 'btn-primary') || (step.status.id === 3 && 'btn-dark') || 'btn-default'"> -->
        <div class='progress_inner__step'>

            <label for='step-1'></label>
        </div>
    <!-- <div class='progress_inner__step'>
      <label for='step-2'></label>
    </div>
    <div class='progress_inner__step'>
      <label for='step-3'></label>
    </div>
    <div class='progress_inner__step'>
      <label for='step-4'></label>
    </div>
    <div class='progress_inner__step'>
      <label for='step-5'></label>
    </div> -->

    <div class='progress_inner__bar'></div>
    <div class='progress_inner__bar--set'></div>

  </div>
</div>

</template>

<script>
    export default {
        name : 'StepLine',
        props : ['steps'],
        data() {
            return {
            }
        },
        mounted(){
            
        },
        computed: {
            
        },
        components: {
        },
    }

</script>
<style scoped>

body .progress_inner__step:before, body .progress_inner, body .progress_inner__status .box_base, body .progress_inner__status .box_item, body .progress_inner__status .box_ribbon, body .progress_inner__status .box_bow, body .progress_inner__status .box_bow__left, body .progress_inner__status .box_bow__right, body .progress_inner__status .box_tag, body .progress_inner__status .box_string {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin: auto;
}

body .progress_inner__bar, body .progress_inner__bar--set {
  height: 6px;
  left: 10%;
  background: repeating-linear-gradient(45deg, #1ea4ec, #1ea4ec 4px, #1f8bc5 4px, #1f8bc5 10px);
  -webkit-transition: width 800ms cubic-bezier(0.915, 0.015, 0.3, 1.005);
  transition: width 800ms cubic-bezier(0.915, 0.015, 0.3, 1.005);
  border-radius: 6px;
  width: 0;
  position: relative;
  z-index: -1;
}

body .progress_inner__step:before {
  width: 30px;
  height: 30px;
  color: #70afd0;
  background: white;
  line-height: 30px;
  border: 3px solid #a6cde2;
  font-size: 12px;
  top: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  cursor: pointer;
  pointer-events: none;
}

body .progress_inner__step {
  width: 20%;
  font-size: 14px;
  padding: 0 10px;
  -webkit-transition: all 0.4s;
  transition: all 0.4s;
  float: left;
  text-align: center;
  position: relative;
}
body .progress_inner__step label {
  top: -20px;
  display: block;
  position: relative;
}
body .progress_inner__step:hover {
  color: white;
}
body .progress_inner__step:hover:before {
  color: white;
  background: #1ea4ec;
}

body .progress_inner__step:before {
  content: "";
}

body .progress_inner__bar--set {
  width: 80%;
  top: -6px;
  background: #70afd0;
  position: relative;
  z-index: -2;
}
</style>