<template>
<div class="login-page">
    <div class="login-box">
        <div class="card card-outline card-primary">
            <div class="card-header text-center">
                <span class="h3" style="font-weight: 300">Connexion espace client</span>
            </div>
            <div class="card-body login-card-body">
                <div v-if="error" class="alert alert-danger">
                    <h5><i class="icon fas fa-ban"></i> Connexion impossible</h5>
                    Votre email ou votre mot de passe est incorrect
                </div>
                <form @submit.prevent="submit">
                    <div class="input-group mb-3" data-children-count="1">
                        <input v-model="email" type="email" name="email" class="form-control" value="" placeholder="Email" style="color: rgb(49, 49, 49);">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-envelope"></span>
                            </div>
                        </div>
                    </div>

                    <div class="input-group mb-3" data-children-count="1">
                        <input v-model="password" type="password" name="password" class="form-control " placeholder="Mot de passe" style="color: rgb(49, 49, 49);">
                        <div class="input-group-append">
                            <div class="input-group-text">
                                <span class="fas fa-lock"></span>
                            </div>
                        </div>
                    </div>
                    <div class="mt-2 mb-1 small text-right">
                        <a href="#" @click="userModalOpen=true">
                            J'ai oublié mon mot de passe
                        </a>
                    </div>
                    <EditMailModal v-if="userModalOpen" @close="userModalOpen=false"></EditMailModal>
                    <div class="row mt-4 pt-2 mb-2">
                        <div class="col-7">
                            <div class="icheck-primary" data-children-count="1">
                                <input type="checkbox" name="remember" id="remember">
                                <label class="form-check-label small" for="remember">Se souvenir de moi</label>
                            </div>
                        </div>
                        <div class="col-5">
                            <button type="submit" class="btn btn-primary btn-block btn-flat" :disabled="sending"><i class="fas fa-spin fa-spinner" v-if="sending"></i>
                                Connexion
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    import axios from 'axios'
    import EditMailModal from './users/EditMailModal.vue';
    export default {
        data() {
            return {
                userModalOpen:false,
                email: '',
                password: '',
                error: false,
                sending: false,
            }
        },
        methods: {
            submit() {
                this.error = false;
                this.sending = true;
                axios.get('/sanctum/csrf-cookie').then(() => {
                    axios.post('login', { email: this.email, password:this.password }).then(r => {
                        this.$store.dispatch('getUser').then(() => {
                            // force reload page to update header
                            window.location='/dossiers'
                            this.sending = false;
                        }).catch(() => {
                            this.error = true;
                            this.sending = false;
                        })
                    }).catch(() => {
                        this.error = true;
                        this.sending = false;
                    })
                });
            }
        },
        components: {
            EditMailModal,
        }
    }
</script>

<style scoped>
.login-card-body, .register-card-body {
    background: #ffffff;
    border-top: 0;
    color: #666;
    padding: 20px;
}
.login-box, .register-box {
    width: 360px;
}
.login-page, .register-page {
    align-items: center;
    background: #e9ecef;
    display: flex;
    height: 100vh;
    justify-content: center;
}
</style>
