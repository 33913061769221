<style>
    .page-item.disabled .page-link {
        color: #ccc !important;
        background-color: #eee !important;
        border-color: #eee !important;
    }
</style>
<template>

    <!-- Main content -->
    <div class="content py-5">
        <div class="container">
            <div class="row">
                <!-- col-md-8 COLONNE GAUCHE -->
                <div class="col-md-6">
                    <div class="mb-3">
                        <h1 class="mb-5">Tous les dossiers ({{folders ? folders.length : ''}})</h1>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="mb-3 text-right" v-if="user && user.role && (user.role.slug === 'admin' || user.role.slug === 'manager')">
                        <button type="button" class="btn btn-primary" @click="$router.push('/dossiers/add')"><i class="fas fa-plus"></i> Nouveau Dossier</button>
                    </div>
                </div>
            </div>
            <div>
                <div class="form-row">
                    <div class="states col" data-children-count="1">
                        <span class="small font-weight-bold">Rechercher</span>
                        <input type="text" class="form-control" placeholder="Dossier, client, responsable, géomètre expert" v-model="listfilter">
                    </div>
                    <div class="states col" data-children-count="1">
                        <span class="small font-weight-bold">Statut</span>
                        <v-select multiple label="name" :options="$store.state.status.items" v-model="listControl.status"></v-select>
                    </div>
                    <div class="states col" data-children-count="1">
                        <span class="small font-weight-bold">Trier</span>
                        <select class="form-control form-control-sm custom-select" v-model="listControl.order">
                            <option value="created_at">Le plus récent</option>
                            <option value="name">Alphabétique</option>
                        </select>
                    </div>
                </div>
                <div class="w-100 d-inline-block py-4" >
                    <h5>Votre liste : {{folders ? folders.length : ''}} résultats</h5>
                </div>
                <div class="table-responsive">
                    <table class="table table-borderedx table-striped dossiers">
                        <!-- <thead>
                            <tr>
                                <th class="small border-top-0">Client & Numéro du dossier</th>
                                <th class="small border-top-0">Géomètre responsable du dossier</th>
                                <th class="small border-top-0 text-center">Statut du dossier</th>
                            </tr>
                        </thead> -->
                        <tbody>
                            <FolderRow :folder="folder" v-for="folder in pageOfFolders" :key="folder.id"></FolderRow>
                        </tbody>
                    </table>
                </div>
                <div class="text-center mt-4">
                    <jw-pagination :labels="labels" :items="folders" @changePage="onChangePage" ></jw-pagination>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from "axios";
import FolderRow from './FolderRow.vue'

    export default {
        name : 'Folders',
        data() {
            return {
                listControl: {
                    filter: null,
                    order : "created_at",
                    status: [],
                },
                pageOfFolders: [],
                labels: {
                    first: 'Première page',
                    last: 'Dernière page',
                    previous: 'Précédente',
                    next: 'Suivante'
                }
            }
        },
        mounted() {
            // Refresh folders
            axios.get('/api/users').then(r => this.$store.commit('setUsers', r.data));
            axios.get('/api/folders').then(r => this.$store.commit('setFolders', r.data));
        },
        computed: {
            folders() {
                let folders = this.$store.state.folders.items ? this.$store.state.folders.items.sort((a, b) => {
                    if (this.listControl.order === 'created_at') {
                        return new Date(b.created_at) > new Date(a.created_at) ? 1 : -1;
                    }
                    return a[this.listControl.order] > b[this.listControl.order] ? 1 : -1;
                }) : [];

                if (this.listControl.status && this.listControl.status.length > 0) {
                    folders = folders.filter(folder => this.listControl.status.map(s => s.slug).includes(folder.status.slug))
                }

                if (this.listfilter) {

                    folders = folders.filter( folder => {
                        const clientNames = folder.clients.filter(c =>  {
                            return c.name.toUpperCase().includes(this.listfilter.toUpperCase())
                                || c.email.toUpperCase().includes(this.listfilter.toUpperCase())
                                || (c.company && c.company.name.toUpperCase().includes(this.listfilter.toUpperCase()))
                        })

                        return (
                            folder.name.toUpperCase().includes(this.listfilter.toUpperCase())
                            || folder.number.toString().toUpperCase().includes(this.listfilter.toUpperCase())
                            || (folder.manager && folder.manager.name.toUpperCase().includes(this.listfilter.toUpperCase()))
                            || (folder.technician && folder.technician.name.toUpperCase().includes(this.listfilter.toUpperCase()))
                            || (folder.manager && folder.manager.email.toUpperCase().includes(this.listfilter.toUpperCase()))
                            || (folder.technician && folder.technician.email.toUpperCase().includes(this.listfilter.toUpperCase()))
                            || folder.number === this.listfilter
                            || clientNames.length
                        )
                    })
                }

                return folders;
            },
            user() {
                return this.$store.state.user.data;
            },
            listfilter: {
                get() {
                    return this.listControl.filter !== null ? this.listControl.filter : (this.user && (this.user.role.slug ==='manager' || this.user.role.slug === 'admin') ? this.user.name : '');
                },
                set(val) {
                    this.listControl.filter = val;
                }
            },
        },
        methods : {
            onChangePage(p) {
                this.pageOfFolders = p
            },
        },
        components: {
            FolderRow,
        },
    }

</script>


<style scoped>
.dossiers tr:hover {
    background:#d3dadb;
}
.custom-dropdown {
        background-color: #f2f2f2;
        border-color: #f2f2f2;
        color: black;
    }

    .edit-button {
        right: 0;
    }

    footer {
        background: grey;
    }

    button.toggle {
        background: transparent;
    }
    button.toggle:focus {
        box-shadow: none;
    }

    .card-header {
        background: #e3e3e3;
    }

    </style>
