<template>
    <tr>
        <td>
            <strong v-if="comment.user">{{ comment.user.firstname }} {{ comment.user.lastname }}</strong>
            <br/>
            <small>{{(new Date(comment.created_at)).toLocaleDateString('fr')}}</small>
        </td>
        <td>
            {{ comment.body.length > 90 ? `${comment.body.substring(0, 90)}...` : comment.body }}
            <span v-if="comment.body.length > 100" data-toggle="tooltip" :title="comment.body">
                <i class="fas fa-eye"></i>
            </span>
        </td>
        <td style="white-space: nowrap;" class="text-right">
            <button class="btn btn-link text-info" v-if="user.id === comment.user.id" @click="editComment">
                <i class="fas fa-edit"></i>
            </button>
            <button class="btn btn-link text-danger" v-if="user.id === comment.user.id" @click="deleteComment">
                <i class="fas fa-spin fa-spinner" v-if="deleting"></i>
                <i class="fas fa-trash" v-else></i>
            </button>
            <Comments  v-if="modal === 'comment'" @close="modal=null" :file="file" :folder="folder" :step="step" :comment="comment"></Comments>
        </td>

    </tr>
</template>

<script>
import axios from 'axios'

import Comments from '../modals/Comments.vue';

export default {
    props: ['comment', 'file', 'folder', 'step'],
    data() {
        return {
            deleting: false,
            modal: null,
        }
    },
    methods: {
        editComment() {
            this.modal = 'comment'
        },
        deleteComment() {
            if (confirm('Votre commentaire sera définitivement supprimé. Souhaitez-vous continuer ?')) {
                this.deleting = true
                axios.delete(`/api/folders/${this.folder.id}/procedures/${this.folder.procedure.id}/steps/${this.step.id}/documents/${this.file.id}/comments/${this.comment.id}`).then(() => {
                    axios.get('/api/folders').then(r => {
                        this.$store.commit('setFolders', r.data)
                        this.deleting = false;
                    });
                })
            }
        },
    },
    components: {
        Comments,
    },
    computed: {
        user() {
            return this.$store.state.user.data;
        }
    },
}
</script>
