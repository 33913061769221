<template>
<div class="modal-backdrop" style="z-index:1065">
    <div class="modal" tabindex="-1" role="dialog" style="display: block;z-index: 1070">
        <div class="modal-xl modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Envoyer notification d'un document
                    </h5>
                    <button type="button" class="close" v-on:click="$emit('close')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body p-3" style="min-height:400px">
                    <div class="form-group">
                        <label>Modèle</label>
                        <v-select :options="templates" label="title" v-model="template" @input="templateChanged"></v-select>
                    </div>
                    <div class="form-group" v-if="template">
                        <label>Destinataires</label>
                        <v-select taggable multiple v-model="recipients" :options="clients"></v-select>
                    </div>
                    <div class="form-group" v-if="template">
                        <label>CC</label>
                        <v-select taggable multiple v-model="cc" :options="usersCopy"></v-select>
                    </div>
                    <div class="form-group" v-if="template">
                        <label>CCi</label>
                        <v-select taggable multiple v-model="cci" :options="usersCopy"></v-select>
                    </div>
                    <div class="form-group" v-if="template">
                        <label>
                            Sujet
                            <i class="fas fa-spin fa-spinner ml-4" v-if="loading"></i>
                        </label>
                        <input type="text" v-model="subject" class="form-control">
                    </div>

                    <div class="form-group" v-if="template">
                        <label>
                            Message
                            <i class="fas fa-spin fa-spinner ml-4" v-if="loading"></i>
                        </label>
                        <textarea v-model="message" class="form-control" rows="10"></textarea>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" @click="send">
                        <i class="fas fa-spin fa-spinner" v-if="sending"></i>
                        <span>Envoyer</span>
                    </button>
                    <button type="button" class="btn btn-light" @click="$emit('close')">Fermer</button>
                </div>
            </div>
        </div>
    </div>
    <!-- END MODAL -->
</div>
</template>
<script>
    import axios from 'axios'
    export default {
        name : 'NotifyDocument',
        props: ['documents', 'folder'],
        data() {
            return {
                add: false,
                template: null,
                subject: null,
                message: null,
                recipients: this.folder.clients && this.folder.clients.length ? [...new Set(this.folder.clients.map(c => c.email))] : [],
                clients: this.folder.clients.map(c => c.email),
                cc: [],
                cci: [],
                sending: false,
                loading: false,
            }
        },
        mounted() {
            console.log('folder', this.folder)
            this.folder.technician && this.cc.push(this.folder.technician.email)
            this.folder.manager && this.cc.push(this.folder.manager.email)
            this.user && this.cc.push(this.user.email)
        },
        methods: {
            send() {
                this.sending = true;
                const url = `/api/documents/notify`
                const data = {
                    recipients: this.recipients,
                    cc: this.cc,
                    cci: this.cci,
                    message: this.message,
                    subject: this.subject,
                }

                axios.post(url, data).then(() => {
                    this.sending = false;
                    this.$emit('success')
                }).catch(e => {
                    this.sending = false;
                    alert("Impossible d'envoyer le message : " + e.message)
                })
            },
            templateChanged() {
                this.loading = true;
                this.subject = ''
                this.message = ''
                console.log('documents', this.documents);
                axios.post(`/api/templates/${this.template.id}`,  {
                    folder: this.folder,
                    documents: this.documents,
                }).then(r => {
                    this.subject = r.data.subject
                    this.message = r.data.message
                    this.loading = false
                }).catch(e => {
                    this.loading = false
                    alert("Impossible de charger le modèle : " + e.message)
                })
            }
        },
        computed: {
            usersCopy () {
                let users = this.folder.clients.map(c => c.email)
                this.folder.technician && users.push(this.folder.technician.email)
                this.folder.manager && users.push(this.folder.manager.email)
                this.user && users.push(this.user.email)
                console.log('this.template.slug', this.template.slug)
                if (this.template.slug === 'document-immobilier') {
                    users.push('aurelie.sola@valoris.expert')
                    this.cc.push('aurelie.sola@valoris.expert')
                } else {
                    this.cc = this.cc.filter(e => e !== 'aurelie.sola@valoris.expert')
                }
                return [...new Set(users)]
            },
            user() {
                return this.$store.state.user.data;
            },
            templates() {
                return this.$store.state.mailTemplates.items;
            }
        },
    }

</script>


<style scoped>
.text-info {
    font-size: 16px;
}
</style>
