<template>
<div class="container py-1">
    <h4>Client</h4>
    <ul class="nav nav-tabs  mt-3">
        <li class="nav-item">
            <a href="#" :class="'nav-link' + (clientType===1 ? ' active' :'')" @click.prevent="setPart">Particulier</a>
        </li>
        <li class="nav-item">
            <a href="#" :class="'nav-link' + (clientType===0 ? ' active' :'')" @click.prevent="setPro">Professionnel</a>
        </li>
    </ul>
    <div class="p-3" style="border:1px solid #dee2e6;border-top:none; background: #fff">
        <div v-if="clientType===0">
            <!-- ENTREPRISE -->

            <div class="form-group">
                <label class="control-label small"><strong>Entreprise</strong></label>

                <v-select :options="searchCompanies" label="name" v-model="comp" @search="companiesRequest" @input="contacts.clients = []">
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            {{ option.name }} <small v-if="option.users">- {{option.users.length}} contacts</small>
                        </div>
                    </template>
                    <template #no-options="{ search, searching, loading }">
                        Entrez quelques caractères pour rechercher une entreprise
                    </template>
                </v-select>
            </div>
            <div class="form-group" v-if="comp">
                <label class="control-label small"><strong>Contact(s)</strong></label>
                <v-select multiple label="name" v-model="contacts.clients" :options="companycontacts" :disabled="saving" @input="setClients">
                    <template #no-options="{ search, searching, loading }">
                        Aucun contact pour cette entreprise
                    </template>
                </v-select>

                <div class="row">
                    <userCard v-for="client in contacts.clients" :key="client.email" :client="client" @savedContact="saveClientContact"></userCard>
                </div>
            </div>
        </div>
        <div v-if="clientType===1">
            <!-- CLIENT -->
            <div class="form-group">
                <label class="control-label small"><strong>Contact</strong></label>
                <v-select multiple label="name" v-model="contacts.clients" :options="searchContacts" @search="contactsRequest" :disabled="saving" @input="setClients">
                    <template slot="option" slot-scope="option">
                        <div class="d-center">
                            <span v-if="option.firstname">{{ option.firstname }}</span>
                            <span v-if="option.lastname">{{ option.lastname }}</span>
                            <span v-if="!option.lastname && !option.firstname && option.name">{{ option.name }}</span>

                            <span v-if="option.email">- {{ option.email }}</span>

                            <small v-if="option.role">- {{option.role.name}}</small>
                        </div>
                    </template>
                    <template #no-options="{ search, searching, loading }">
                        Entrez quelques caractères pour rechercher un contact
                    </template>
                </v-select>

                <div class="row" v-if="contacts.clients && contacts.clients.length">
                    <userCard v-for="user in contacts.clients" :key="user.email" :client="user" @savedContact="saveClientContact"></userCard>
                </div>
            </div>
        </div>
    </div>



    <!-- PARTENAIRE -->
    <!-- <div class="form-group mt-4">
        <h4>Partenaires</h4>
        <v-select multiple label="name" v-model="contacts.partners" :options="all_contacts" :disabled="saving">
            <template slot="option" slot-scope="option">
                <div class="d-center">
                    {{ option.firstname }} {{ option.lastname }} <small v-if="option.role">- {{option.role.name}} <strong v-if="option.company">({{ option.company.name }})</strong></small>
                </div>
            </template>
        </v-select>
        <a class="text-sm text-info" @click="modal='addPartner'"><i class="fas fa-plus"></i> <span class="ml-1">Créer un partenaire</span></a>

        <div class="row">
            <userCard  v-for="partner in contacts.partners" :key="partner.email" :client="partner" @savedContact="savePartner"></userCard>
        </div>
    </div> -->
    <AddClient v-if="modal === 'addClient'" :company="comp" @addContact="addContact" @close="modal=null"></AddClient>
    <!-- <AddPartenaire v-if="modal === 'addPartner'" @addContact="addPartner" @close="modal=null"></AddPartenaire> -->

</div>
</template>
<script>
    import axios from 'axios';

    import AddClient from "./modals/AddClient.vue";
    // import AddPartenaire from "./modals/AddPartenaire.vue";
    import userCard from "./components/UserCard.vue";

    const mapContacts = (all, contact) => {
        return all.map(c => {
            if (c.email === contact.email) {
                return contact
            }
            return c;
        })
    }

    export default {
        name : 'Intervenants',
        props : ['project', 'clientType', 'company'],
        data() {
            return {
                log: console.log,
                // entreprise : null,
                // user_clients : [],
                // partenaires : [],
                contacts: {...this.project.contacts},
                searchContacts: [],
                searchCompanies: [],
                comp: this.company ? {...this.company} : null,
                modal : null,
                saving : false,
                lastSearch: null,
                lastCompanySearch: null,
                contactSearchCancelToken: null,
                companySearchCancelToken: null,
            }
        },
        methods : {
            saveClientContact(contact) {
                this.$store.commit('addContact', contact)
                this.contacts.clients = mapContacts(this.contacts.clients, contact)
            },
            contactsRequest(search, loading) {
                if (!search) {
                    return
                }
                if (this.lastSearch) {
                    clearTimeout(this.lastSearch);
                    this.lastSearch = null;
                }
                this.lastSearch = setTimeout(() => {
                    this.getContacts(search, loading)
                }, 500)
            },
            companiesRequest(search, loading) {
                if (!search) {
                    return
                }
                if (this.lastSearch) {
                    clearTimeout(this.lastSearch);
                    this.lastSearch = null;
                }
                this.lastSearch = setTimeout(() => {
                    this.getCompanies(search, loading)
                }, 500)
            },
            getContacts(search, loading) {
                if (!search) {
                    return
                }

                if (this.contactSearchCancelToken) {
                    this.contactSearchCancelToken.cancel()
                    this.contactSearchCancelToken = null;
                }
                const CancelToken = axios.CancelToken;
                this.contactSearchCancelToken = CancelToken.source();
                loading(true)
                axios.get('/api/contacts/search?s='+search, {
                    cancelToken: this.contactSearchCancelToken.token
                }).then(res => {
                    this.searchContacts = res.data.sort((a, b) => a.name > b.name ? 1 : -1);
                    loading(false)
                }).catch(() => {
                    loading(false)
                })
            },

            getCompanies(search, loading) {
                if (!search || this.lastSearchTime + 1000 > (new Date()).getTime()) {
                    return
                }
                this.lastSearchTime = (new Date()).getTime();
                if (this.companySearchCancelToken) {
                    this.companySearchCancelToken.cancel()
                    this.companySearchCancelToken = null
                }
                const CancelToken = axios.CancelToken;
                this.companySearchCancelToken = CancelToken.source();
                loading(true)
                axios.get('/api/companies/search?s='+search, {
                    cancelToken: this.companySearchCancelToken.token
                }).then(res => {
                    this.searchCompanies = res.data.sort((a, b) => a.name.localeCompare(b.name));
                    loading(false)
                }).catch(() => {
                    loading(false)
                })
            },
            savePartner(contact) {
                this.contacts.partners = mapContacts(this.contacts.partners, contact)
                this.$store.commit('addContact', contact)
            },
            setPro() {
                this.contacts.clients = [];
                this.$emit('setInfo', {contacts: this.contacts, personType: 0, company: this.comp})
            },
            setPart() {
                this.contacts.clients = [];
                this.comp = null;
                this.$emit('setInfo', {contacts: this.contacts, personType: 1, company: this.comp})
            },
            addContact(val) {
                if (!this.contacts.clients) {
                    this.contacts.clients = [];
                }
                this.contacts.clients.push(val)
                this.$emit('setInfo', {contacts: this.contacts, personType: this.clientType, company: this.comp})
            },
            setClients(val) {
                this.$emit('setInfo', {contacts: this.contacts, personType: this.clientType, company: this.comp})
            },
            addPartner(val) {
                console.log('adding partner', val)
                if (!this.contacts.partners) {
                    this.contacts.partners = [];
                }
                this.contacts.partners.push(val)
                this.$emit('setInfo', {contacts: this.contacts, personType: this.clientType, company: this.comp})
            },
        },
        beforeDestroy() {
            console.log(' this.contacts',  this.contacts)

            this.$emit('setInfo', {contacts: this.contacts, personType: this.clientType, company: this.comp})
        },
        mounted() {
            this.$store.subscribe((mutation, state) => {
                if (mutation.type === 'showModal' || mutation.type === 'hideModal') {
                    this.modal = state.modals.shown;
                }
            });
        },
        computed: {
            companycontacts() {
                return this.comp ? this.comp.users : [];
            },
        },
        components: {
            AddClient,
            // AddPartenaire,
            userCard,
        },
    }

</script>


<style scoped>
.edit-button {
    right: 20px;
    top: 10px;
    z-index: 10;
}
.nav-link.active {
    color: #0f46d1;
}
.nav-link {
    color: #666;
}
</style>
