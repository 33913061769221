<template>
    <div>
        <div class="modal-backdrop">
            <div class="modal" tabindex="-1" role="dialog" style="display: block" v-on:click.self="$store.commit('hideModal')">
                <div class="modal-dialog modal-xl modal-dialog-scrollable modal-dialog-centered" role="document">
                    <div class="modal-content" style="min-height:400px">
                        <div class="modal-header">
                            <h5 class="modal-title">Partenaires du dossier<br/>
                            </h5>
                            <button type="button" class="close" v-on:click="$store.commit('hideModal')" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body p-3">
                            <div v-if="user.role && (user.role.slug === 'admin' || user.role.slug === 'manager')" class="form-group">
                                <div class="row">
                                    <div class="col-md-12">
                                        <label>Ajouter un partenaire</label>
                                    </div>

                                    <div class="col-md-10">
                                        <v-select label="name" v-model="addedPartner" :options="all_contacts" :disabled="saving">
                                            <template slot="option" slot-scope="option">
                                                <div class="d-center">
                                                    {{ option.firstname }} {{ option.lastname }} <small v-if="option.role">- {{option.role.name}} <strong v-if="option.company">({{ option.company.name }})</strong></small>
                                                </div>
                                            </template>
                                        </v-select>
                                    </div>
                                    <div class="col-md-2">
                                        <button :disabled="!this.addedPartner"  class="btn btn-info float-right" @click="addPartner">
                                            <i class="fas fa-spin fa-spinner" v-if="saving"></i>
                                            <i class="fas fa-plus" v-else></i>
                                            Ajouter</button>
                                    </div>
                                </div>
                            </div>
                            <table class="table table-borderedx table-striped">
                                <thead>
                                    <tr>
                                        <th class="small border-top-0">Nom</th>

                                        <th class="small border-top-0">Email</th>
                                        <th class="small border-top-0">Société</th>
                                        <th class="small border-top-0"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                <UserLine v-for="user in partners" :key="user.id" :user="user" :folder="folder" :canDelete="user && user.role && (user.role.slug === 'admin' || user.role.slug === 'manager')"></UserLine>
                                <tr v-if="!partners || partners.length === 0">
                                    <td colspan="4">
                                        <div class="text-center text-muted">
                                            Aucun partenaire
                                        </div>
                                    </td>
                                </tr>

                                </tbody>
                            </table>

                        </div>
                        <div class="modal-footer">
                            <button v-if="user.role && (user.role.slug === 'admin' || user.role.slug === 'manager')" class="btn btn-primary" @click="addPartnerModal=true"><i class="fas fa-plus"></i> Nouveau partenaire</button>
                            <button type="button" class="btn btn-light" @click="$store.commit('hideModal')">Fermer</button>
                        </div>
                    </div>
                </div>
            </div>
            <!-- END MODAL -->
        </div>
        <AddPartenaire v-if="addPartnerModal" @addContact="createdPartner" @close="addPartnerModal=false"></AddPartenaire>
</div>
</template>
<script>
    import axios from 'axios';

    import AddPartenaire from '../../add/modals/AddPartenaire.vue'
    import UserLine from './UserLine.vue';

    export default {
        name : 'PartnersModal',
        props: ['partners', 'folder'],
        data() {
            return {
                addPartnerModal: false,
                addedPartner: null,
                saving: false,
            }
        },
        methods: {
            createdPartner(val) {
                this.addedPartner = val;
                this.addPartner();
            },
            addPartner() {
                this.saving = true
                const folder = {...this.folder}

                folder.contacts = {partners: [this.addedPartner]};

                axios.put(`/api/folders/${folder.id}`, folder).then(() => {
                    axios.get('/api/folders').then(r => this.$store.commit('setFolders', r.data)).then(() => {
                        this.saving = false
                        this.addedPartner = null;
                    }).catch(() => {
                        this.saving = false;
                        this.addedPartner = null;
                    });
                })
            }
        },
        mounted(){

        },
        computed: {
            user: function () {
                return this.$store.state.user.data
            },
            all_contacts() {
                const part = this.partners.map(p => p.id)
                return this.$store.state.contacts.items.filter(c => part.indexOf(c.id) === -1)
            },
        },
        components: {
            AddPartenaire,
            UserLine,
        },
    }

</script>


<style scoped>
.text-info {
    font-size: 16px;
}
.v-select {
    position:relative !important;
}
    ul[role="listbox"], .vs__dropdown-menu, .v-select ul * {
        z-index:10000 !important;
        position:relative !important;
    }
</style>
