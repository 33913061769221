<template>
    <div class="wrapper">
        <Menu v-if="user"></Menu>

        <div class="content-wrapper bg-light">
            <router-view></router-view>
        </div>
        <CGUModal v-if="user && user.cgu === false" :user="user"></CGUModal>
        <div class="text-muted text-center py-2" style="border-top: 1px solid #eee">
            <a class="text-muted" href="#" @click.prevent="showCGU=true">Conditions Générales d'Utilisation</a>
            <CGUModal v-if="showCGU" @close="showCGU=false"></CGUModal>
        </div>

    </div>
</template>

<script>

    import axios from "axios";
    import CGUModal from "./components/users/CGUModal";

    export default {
        data() {
            return {
                showCGU: false,
            }
        },
        computed : {
            user: function () {
                return this.$store.state.user.data
            }
        },
        methods: {
            logout() {
                this.$store.dispatch('logout')
            }
        },
        components: {
            CGUModal,
        }
    }
</script>
<style>

</style>
