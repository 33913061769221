<template>
<div class="modal-backdrop">
    <div class="modal" tabindex="-1" role="dialog" style="display: block" @click.self="$emit('close')">
        <div class="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <span v-if="doctype==='contracts'">Ajouter un devis / contrat</span>
                        <span v-else-if="doctype==='bills'">Ajouter une facture</span>
                        <span v-else>Ajouter un document</span>
                        <br/>
                        <small v-if="i">Étape {{i+1}} : {{ step.name }}</small>
                    </h5>
                    <button type="button" class="close" @click="$emit('close')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body p-4" v-if="nextcloud">
                    <div class="form-group">
                        <label>Date du document</label>
                        <DatePicker format="dd/MM/yyyy" :language="fr" v-model="date" name="date" input-class="form-control" :typeable="true"></DatePicker>
                    </div>
                    <div class="form-group mt-2" v-if="!doctype">
                        <label>Type de document (optionnel)</label>
                        <v-select v-if="documentTypes" v-model="type" label="name" :options="documentTypes" ></v-select>
                    </div>
                    <div class="form-group mt-2">
                        <label>Commentaire</label>
                        <textarea  id="comment" v-model="comment" class="form-control form-control-sm" rows="3"></textarea>
                    </div>

                    <label>Choisir le document</label>
                    <ul class="nav nav-tabs  mt-3">
                        <li class="nav-item">
                            <a href="#" :class="'nav-link' + (uploadFrom=='local' ? ' active' :'')" @click.prevent="setUploadMethod('local')">Depuis votre ordinateur</a>
                        </li>
                        <li class="nav-item">
                            <a href="#" :class="'nav-link' + (uploadFrom=='nextcloud' ? ' active' :'')" @click.prevent="setUploadMethod('nextcloud')">Depuis NextCloud</a>
                        </li>
                    </ul>
                    <div class="p-3" style="border:1px solid #dee2e6;border-top:none">
                        <div class="custom-file" v-if="uploadFrom === 'local'">
                            <input multiple type="file" class="custom-file-input" id="file" lang="fr" @change="newFile" :class="{ 'is-invalid': submitted && $v.file.$error }">
                            <label class="custom-file-label" for="file">
                                {{ filesLabel }}
                            </label>
                            <div class="text-danger" v-if="submitted && !$v.file.required"><b>Fichier obligatoire</b></div>

                            <!--<div class="text-danger" v-if="this.file && !$v.file.fileSize"><b>Fichier trop volumineux</b></div>-->
                        </div>
                        <div class="mt-3"  v-if="uploadFrom === 'nextcloud'">
                            <div v-if="loading" class="text-muted text-center">
                                Chargement en cours
                            </div>
                            <Nextcloud v-else :contents="contents ? contents.children : []" selectType="file" :openFolder="projectFolder"></Nextcloud>

                        </div>
                    </div>




                </div>
                <div class="modal-body p-4" v-else>
                    <div class="alert alert-danger">
                        NextCloud est actuellement indisponible. Aucun document ne peut être ajouté, désolé.
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" @click="$emit('close')">Annuler</button>
                    <button type="button" :disabled="((!this.$store.getters.selectedFiles || this.$store.getters.selectedFiles.length === 0) && !file) && (!doctype || type)" class="btn btn-primary" @click="save"> <i class="fa fa-spinner fa-spin" v-if="saving"></i> Valider</button>
                </div>
            </div>
        </div>
    </div>
</div>
    <!-- END MODAL -->
</template>
<script>
    import DatePicker from '@sum.cumo/vue-datepicker'
    import '@sum.cumo/vue-datepicker/dist/vuejs-datepicker.css'
    import {fr} from '@sum.cumo/vue-datepicker/dist/locale'
    import axios from 'axios'

    import { required } from 'vuelidate/lib/validators'
    import Nextcloud from '../../../nextcloud';
    import {toBase64} from '../../../../utils'

    export default {
        name : 'AddDocument',
        props : ['step', 'project', 'i', 'doctype'],
        data() {
            return {
                type : null,
                file: null,
                files: [],
                date: new Date(),
                comment : null,
                saving : false,
                submitted : false,
                uploadFrom: 'nextcloud',
                nextcloud: true,
                fr,
                loading: false,
            }
        },
        validations: {
            file: {  //,
                required,
                //fileSize () { return this.file ? this.file.size ? this.file.size < config.MAX_FILE_SIZE : true : false }
            },
        },
        methods : {
            newFile(ev) {
                if (ev.target.files.length) {
                    this.file = ev.target.files[0]
                    this.files = ev.target.files
                }
            },
            setUploadMethod(method) {
                this.uploadFrom = method;
                if (method === 'nextcloud') {
                    console.log(this.project)

                    // Open project dir

                }
            },
            save () {
                this.saving = true
                this.submitted = true;

                this.$v.$touch();
                if (this.uploadFrom === 'local' && this.$v.$invalid) {
                    this.saving = false
                    return
                }
                if (this.uploadFrom === 'nextcloud') {
                    // File is from NextCloud, just assign it to this project
                    this.files = this.$store.getters.selectedFiles

                    console.log('selected file(s)', this.files);

                    const docs = [...this.files].map(file => {
                        return {
                            name: file.basename,
                            path: file.path.replace(`remote.php/webdav/${window.WEBDAV_ROOT}`, ''),
                            geo_type_id: this.type ? this.type.id : null,
                            date: this.date,
                        }
                    })

                    if (this.doctype) {
                        this.uploadDocuments(docs);
                    } else {
                        this.uploadStepDocuments(docs);
                    }
                } else {
                    const promises = [...this.files].map((file) => toBase64(file))

                    Promise.all(promises).then(results => {
                        const docs = results.map(r => {
                            return {
                                name: r.file.name,
                                data: r.data,
                                geo_type_id: this.type ? this.type.id : null,
                                date: this.date,
                            };

                        });

                        if (this.doctype) {
                            this.uploadDocuments(docs)
                        } else {
                            this.uploadStepDocuments(docs)
                        }
                    })
                }
            },
            uploadDocuments(documents) {
                const p = {...this.project};
                //Set new documents on project
                p.documents = {
                    [this.doctype]: documents,
                };
                //Update folder with new documents
                axios.put(`/api/folders/${p.id}`, p).then((res) => {
                    console.log('get folders', res)
                    // Reload all folders from server
                    axios.get('/api/folders').then(r => {
                        this.$store.commit('setFolders', r.data)
                        this.$emit('close')
                        this.saving = false;
                    }).catch(e => {
                        this.saving = false;
                        alert(e.message)
                    });
                }).catch(e => {
                    this.saving = false;
                    alert(e.message)
                })
            },
            async uploadStepDocuments(documents) {
                console.log('upload step documents', documents)
                // save document and link it to this step

                for (const doc of documents) {
                    try {
                        const r = await axios.post(`/api/folders/${this.project.id}/procedures/${this.project.procedure.id}/steps/${this.step.id}/documents`, doc)
                        if (r.data.id && this.comment) {
                            await axios.post(`/api/folders/${this.project.id}/procedures/${this.project.procedure.id}/steps/${this.step.id}/documents/${r.data.id}/comments`, {comment : this.comment})
                        }
                    } catch(e) {
                        this.saving = false;
                        alert(e.message + JSON.stringify(e.response.data))
                    }
                }

                try {
                    const r = await axios.get('/api/folders')
                    this.$store.commit('setFolders', r.data)
                    this.$emit('close')
                } catch (e) {
                    this.saving = false;
                    alert(e.message)
                }
            }
        },
        mounted() {
            //Create project folder on NextCloud
            // Create project dir`DOSSIERS/${(new Date()).getFullYear()}/${this.project.number}`
            this.loading = true;
            this.$store.commit('clearSelected');
            axios.post(`/api/fs/mkdir/${this.projectFolder}`).then(r => {

                axios.get(`/api/fs/ls/${this.projectParentFolder}`).then(parent => {
                    this.$store.commit('addChildren', {
                        path: `remote.php/webdav/${window.WEBDAV_ROOT}${this.projectParentFolder}`,
                        items: parent.data
                    })

                    // create espace client dir
                    axios.post(`/api/fs/mkdir/${this.projectFolder}/Espace client`).then(resp => {
                        console.log('created dir', resp.data);
                        // refresh folder with this project directory

                        axios.get(`/api/fs/ls/${this.projectFolder}`).then(f => {
                            console.log('adding children to path', `remote.php/webdav/${window.WEBDAV_ROOT}${this.projectFolder}`, f.data)
                            this.$store.commit('addChildren', {
                                path: `remote.php/webdav/${window.WEBDAV_ROOT}${this.projectFolder}`,
                                items: f.data
                            })

                            console.log('new nextcloud folders', this.$store.state.nextcloud.items)
                            this.open = true;
                            this.loading = false;

                        }).catch(err => {
                            console.log('get folder error', err)
                            this.loading = false;
                            this.nextcloud = false;
                        })

                    }).catch(e => {
                        console.log('mlkdir err', e)
                        this.nextcloud = false;
                    });
                });

            }).catch(e => {
                console.log('mlkdir err', e)
                this.nextcloud = false;
            });
        },
        computed: {
            documentTypes: function () {
                return this.$store.state.documentTypes.items
            },
            filesLabel() {
                if (!this.files || this.files.length === 0) {
                    return 'Ajouter un fichier';
                }
                console.log('files', this.files)
                return [...this.files].map((f) => f.name).join(' ')
            },
            contents() {
                this.$store.commit('setSearch', `remote.php/webdav/${window.WEBDAV_ROOT}${this.projectFolder}`);
                console.log('this.$store.getters.search', this.$store.getters.search)
                return this.$store.getters.search;
            },
            projectFolder() {
                return `DOSSIERS/20${this.project.number.substr(0, 2)}/${encodeURIComponent(this.project.number).split('%20').join('+')}`;
            },
            projectParentFolder() {
                return `DOSSIERS/20${this.project.number.substr(0, 2)}`;
            }
        },
        components: {
            Nextcloud,
            DatePicker,
        },
    }

</script>

