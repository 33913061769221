<template>
    <ul class="list-group mt-3">
        <NextcloudItem v-if="contents && Array.isArray(contents) && contents.length > 0" v-for="content in contents" :content="content" :key="content.path" :selectType="selectType" :openFolder="openFolder"></NextcloudItem>
        <li class="list-group-item text-muted bg-gray-light" v-if="contents && Array.isArray(contents) && contents.length === 0">
            Aucun fichier
        </li>
    </ul>
</template>

<script>
    import NextcloudItem from './NextcloudItem.vue'

    export default {
        name: 'Nextcloud',
        props: ['contents', 'selectType', 'openFolder'],
        components: {
            NextcloudItem,
        },
        watch: {
            contents(val, oldval){
                console.log('contents val', val, oldval)
            }
        }
    }
</script>