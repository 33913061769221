<template>
<div class="modal-backdrop">
    <div class="modal" tabindex="-1" role="dialog" style="display: block" @click.self="$emit('close')">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="comment">
                        Edition du commentaire du {{(new Date(comment.created_at)).toLocaleDateString('fr')}}
                    </h5>
                    <h5 class="modal-title" v-else>
                        Commentaires
                    </h5>
                    <button type="button" class="close" @click="$emit('close')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="col-12">
                        <div class="form-group pb-4">
                            <!-- COMMENTAIRES -->
                            <div class="mb-2">
                                <table class="mb-3 table table-borderedx table-striped bg-white">
                                    <tr v-for="comm in file.comments" :key="comm.id" v-if="!comment || comm.id !== comment.id">
                                        <td colspan="2" class="p-1 small">
                                            <span v-if="comm.user">{{ comm.user.firstname }} {{ comm.user.lastname }}</span> <br/>
                                            {{(new Date(comm.created_at)).toLocaleDateString('fr')}}
                                        </td>
                                        <td class="p-1"> {{ comm.body }} </td>
                                    </tr>
                                </table>
                                <textarea  id="newComment" v-model="newComment" class="form-control form-control-sm" rows="3" placeholder="Nouveau commentaire"></textarea>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-light" @click="$emit('close')">Annuler</button>
                    <button type="button" class="btn btn-primary" @click="save"><i class="fa fa-spinner fa-spin" v-if="saving"></i> Valider</button>
                </div>
            </div>
        </div>
    </div>
</div>
    <!-- END MODAL -->
</template>
<script>

    export default {
        name : 'Comments',
        props : ['file', 'folder', 'step', 'comment'],
        data() {
            return {
                newComment : this.comment ? this.comment.body : null,
                saving : false,
            }
        },
        validations: {
        },
        methods : {
            save () {
                this.saving = true
                this.submitted = true;

                if (this.comment && this.comment.id) {
                    // Editing an existing comment
                    axios.put(`/api/folders/${this.folder.id}/procedures/${this.folder.procedure.id}/steps/${this.step.id}/documents/${this.file.id}/comments/${this.comment.id}`,
                        {comment : this.newComment}
                    ).then(res => {
                        const documentsUpdated = this.step.documents.map(file => file.id === this.file.id ? ({...file, comments : file.comments.map(c => c.id === res.data.id ? res.data : c)}) : ({...file}))
                        this.updateSteps(documentsUpdated)
                        this.$emit('close')
                    }).catch(() => {
                        this.saving = false
                    })
                } else {
                    axios.post(`/api/folders/${this.folder.id}/procedures/${this.folder.procedure.id}/steps/${this.step.id}/documents/${this.file.id}/comments`,
                        {comment : this.newComment}
                    ).then(res => {
                        const documentsUpdated = this.step.documents.map(file => file.id === this.file.id ? ({...file, comments : file.comments.push(res.data)}) : ({...file}))
                        this.updateSteps(documentsUpdated)
                        this.$emit('close')
                    }).catch(() => {
                        this.saving = false
                    })
                }
            },
            updateSteps(documentsUpdated) {
                let stepsUpdated = this.folder.procedure.steps.map(step => step.id === this.step.id ? {...this.step, documents : documentsUpdated} : {...step})
                this.$store.dispatch('updateProcedureSteps', {steps: stepsUpdated, folder_id: this.folder.id})
            }
        },
        mounted() {
            console.log('a', this.file.comments, this.file)
        },
        computed: {

        },
        components: {
        },
    }

</script>

