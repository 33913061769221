<template>
    <tr>
        <td>
            <strong>{{ document.name }}</strong>
        </td>
        <td>
            <span v-if="document.date">{{ (new Date(document.date)).toLocaleDateString('fr') }}</span>
        </td>
        <td>
            {{ (new Date(document.created_at)).toLocaleDateString('fr') }}
        </td>
        <td>
            <small v-if="document.downloads && document.downloads.length > 0">
                <span v-for="dl in document.downloads">{{dl.user.firstname}} {{dl.user.lastname}} le {{dateString(dl.updated_at)}} <br></span>
            </small>
        </td>
        <td class="text-right">
            <a :href="document.url" class="btn btn-link" target="_blank"><i class="far fa-file-alt"></i><span class="sr-only">Télécharger</span></a>
        </td>

        <td v-if="user && user.role && (user.role.slug ==='admin' || user.role.slug === 'manager')">
            <button class="btn btn-link" @click="modal = 'notify'">
                <i class="fas fa-paper-plane"></i>
                <span class="sr-only">Notifier</span>
            </button>
            <button class="btn btn-link text-danger" @click="deleteDoc">
                <i class="fas fa-spin fa-spinner" v-if="deleting"></i>
                <i class="fas fa-trash" v-else></i>
                <span class="sr-only">Supprimer</span>
            </button>

            <NotifyDocument :folder="folder" :step="null" :documents="[document]" v-if="modal==='notify'" @close="modal=false" @success="success"></NotifyDocument>
            <Toast body="Votre message a bien été envoyé" title="Envoi réussi" v-if="sendSuccess"></Toast>
        </td>
    </tr>

</template>
<script>
    import axios from 'axios'
    import NotifyDocument from '../modals/NotifyDocument.vue';
    import Toast from '../../../../utils/Toast'

    export default {
        name: 'DocumentRow',
        props: ['document', 'folder'],
        data() {
            return {
                deleting: false,
                modal: false,
                sendSuccess: false,
            }
        },
        methods: {
            success(e) {
                this.modal = false
                this.sendSuccess = true
                setTimeout(() => {
                    this.sendSuccess = false
                }, 4000)
            },
            dateString(date) {
                return (new Date(date)).toLocaleDateString('fr');
            },
            deleteDoc() {
                if (confirm(`Vous allez supprimer le document ${this.document.name}. Cette action est irréversible, mais le document sera conservé sur NextCloud. Souhaitez-vous continuer ?`)) {
                    this.deleting = true;
                    axios.delete(`/api/documents/${this.document.id}`).then(() => {
                        axios.get('/api/folders').then(r => {
                            this.$store.commit('setFolders', r.data)
                            this.deleting = false;
                        });
                    }).catch(e => {
                        alert('Suppression du document impossible (' + e.message + ')')
                        this.deleting = false;
                    })
                }
            }
        },
        computed: {
            user() {
                return this.$store.state.user.data;
            }
        },
        components: {
            NotifyDocument,
            Toast,
        }
    }
</script>
