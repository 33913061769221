<template>
<div class="modal-backdrop">
    <div class="modal" tabindex="-1" role="dialog" style="display: block" v-on:click.self="$emit('close')">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Ajouter un partenaire<br/>
                    </h5> 
                    <button type="button" class="close" v-on:click="$emit('close')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body p-3">
                    <form>
                        <div class="form-row"> 
                            <div class="form-group col-md-3">
                                <label class="control-label small" :class="{ 'text-danger': submitted && $v.enterprise.$error }"><b>Société :</b></label>
                            </div>
                            <div class="form-group col-md-7">
                                <input type="text" class="form-control" v-model="enterprise" :class="{ 'is-invalid': submitted && $v.enterprise.$error }">
                                <small class="text-danger" v-if="$v.enterprise.$error"><b>Entreprise obligatoire</b></small>
                            </div>
                        </div>
                        <div class="form-row"> 
                            <div class="form-group col-md-3">
                                <label class="control-label small" :class="{ 'text-danger': submitted && $v.name.$error }"><b>Nom :</b></label>
                            </div>
                            <div class="form-group col-md-7">
                                <input type="text" class="form-control" v-model="name" :class="{ 'is-invalid': submitted && $v.name.$error }">
                                <small class="text-danger" v-if="$v.name.$error"><b>Nom obligatoire</b></small>
                            </div>
                        </div>
                        <div class="form-row"> 
                            <div class="form-group col-md-3">
                                <label class="control-label small" :class="{ 'text-danger': submitted && $v.firstname.$error }"><b>Prénom :</b></label>
                            </div>
                            <div class="form-group col-md-7">
                                <input type="text" class="form-control" v-model="firstname" :class="{ 'is-invalid': submitted && $v.firstname.$error }">
                                <small class="text-danger" v-if="$v.firstname.$error"><b>Prénom obligatoire</b></small>
                            </div>
                        </div>
                        <div class="form-row"> 
                            <div class="form-group col-md-3">
                                <label class="control-label small" :class="{ 'text-danger': submitted && $v.mail.$error }"><b>E-mail :</b></label>
                            </div>
                            <div class="form-group col-md-7">
                                <input type="text" class="form-control" v-model="mail" :class="{ 'is-invalid': submitted && $v.mail.$error }">
                                <small class="text-danger" v-if="$v.mail.$error">E-mail obligatoire</small>
                            </div>
                        </div>
                        <div class="form-row"> 
                            <div class="form-group col-md-3">
                                <label class="control-label small"><b>Portable :</b></label> 
                            </div>
                            <div class="form-group col-md-7">
                                <input class="form-control" type="text" v-model="phone">
                            </div>
                        </div>
                        
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" @click="$emit('close')">Annuler</button>
                    <button type="button" class="btn btn-primary" v-on:click="save()"> 
                        <i class="fa fa-spinner fa-spin" v-if="saving"></i>
                        Valider
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    import { required } from 'vuelidate/lib/validators'

    export default {
        data() {
            return {
                enterprise: null,
                name : null,
                firstname : null,
                mail : null,
                phone : null,
                saving : false,
                submitted : false,
            }
        },
        validations: {
            enterprise: { required },
            name: { required },
            firstname: { required },
            mail: { required },
        },
        methods: {
            async save () {
                this.saving = true
                this.submitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                    this.saving = false
                    return
                }

                const data = {
                    lastname: this.name,
                    firstname: this.firstname,
                    email: this.mail,
                    phone: this.phone,
                    isActive: true,
                    company: this.enterprise,
                    role: 'partner',
                }

                console.log('saving user', data)
                this.$store.dispatch('saveUser', data).then((res) => {
                    this.saving = false;
                    this.$emit('addContact', res.data)
                    this.$emit('close')
                }).catch(er => {
                    this.saving = false;
                    console.log(er.response.data.errors)
                    this.errors = er.response.data.errors;
                })
            },
        },
        mounted() {
            
        },
        computed: {
            
        },
        components: {
        },
    }

</script>


<style scoped>
.text-info {
    font-size: 16px;
}
</style>