<template>
<div class="modal-backdrop">
    <div class="modal" tabindex="-1" role="dialog" style="display: block" @click.self="$emit('close')">
        <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Historique de téléchargement
                    </h5>
                    <button type="button" class="close" @click="$emit('close')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <div class="col-12">
                        <div class="form-group pb-4">
                            <div class="mb-2">
                                <table class="mb-3 table table-borderedx table-striped bg-white">
                                    <tr v-for="dl in file.downloads" :key="dl.id">
                                        <td class="p-1 small border-0"> 
                                            {{dl.user.firstname}} {{dl.user.lastname}} le {{dateString(dl.updated_at)}}
                                        </td>
                                    </tr>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" @click="$emit('close')">Fermer</button>
                </div>
            </div>
        </div>
    </div>
</div>
    <!-- END MODAL -->
</template>
<script>

    export default {
        name : 'Comments',
        props : ['file', 'folder', 'step'],
        data() {
            return {
            }
        },
        validations: {
        },
        methods : {
            dateString(date) {
                return (new Date(date)).toLocaleDateString('fr');
            },
        },
        mounted() {
        },
        computed: {

        },
        components: {
        },
    }

</script>

