<template>
<div class="modal-backdrop">
    <div class="modal" tabindex="-1" role="dialog" style="display: block" v-on:click.self="$store.commit('hideModal')">
        <div class="modal-xl modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" v-if="type==='bills'">
                        Factures
                    </h5>
                    <h5 class="modal-title" v-else>
                        Devis / Contrats
                    </h5>
                    <button type="button" class="close" v-on:click="$store.commit('hideModal')" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body p-3">
                    <table class="table table-borderedx table-striped" v-if="documents && documents.length">
                        <thead>
                        <tr>
                            <th>Nom du fichier</th>
                            <th>Date du document</th>
                            <th>Date d'ajout</th>
                            <th>Téléchargements</th>
                            <th></th>
                            <th v-if="user && user.role && (user.role.slug ==='admin' || user.role.slug === 'manager')"></th>
                        </tr>
                        </thead>
                        <tbody>
                            <DocumentRow v-for="document in documents" :document="document"  :key="document.id" :folder="project"></DocumentRow>
                        </tbody>
                    </table>
                    <div v-else-if="!add" class="text-muted">
                        <span  v-if="type==='bills'">Aucune facture pour ce dossier</span>
                        <span  v-if="type==='contracts'">Aucun contrat pour ce dossier</span>
                    </div>
                    <AddDocument v-if="add" :project="project" @billAdded="add=false" @close="add=false" :doctype="type"></AddDocument>
                </div>
                <div class="modal-footer">
                    <button v-if="!add && user.role && (user.role.slug === 'manager' || user.role.slug === 'admin')" type="button" class="btn btn-outline-secondary" @click="modal='notify'">
                        <span>Notifier tous les documents</span>
                    </button>
                    <button v-if="!add && user.role && (user.role.slug === 'manager' || user.role.slug === 'admin')" type="button" class="btn btn-primary" @click="add=true">
                        <span v-if="type==='bills'">Ajouter une facture</span>
                        <span v-else>Ajouter un devis / contrat</span>
                    </button>
                    <button type="button" class="btn btn-light" @click="$store.commit('hideModal')">Fermer</button>
                </div>
            </div>
        </div>
    </div>
    <!-- END MODAL -->
    <NotifyDocument :folder="project" :step="null" :documents="[...documents]" v-if="modal==='notify'" @close="modal=false" @success="success"></NotifyDocument>
    <Toast body="Votre message a bien été envoyé" title="Envoi réussi" v-if="sendSuccess"></Toast>
</div>
</template>
<script>

    import AddDocument from './AddDocument.vue'
    import NotifyDocument from '../modals/NotifyDocument.vue';
    import Toast from '../../../../utils/Toast'
    import DocumentRow from './DocumentRow.vue'

    export default {
        name : 'ViewDocuments',
        props: ['documents', 'project', 'type'],
        data() {
            return {
                add: false,
                modal: false,
                sendSuccess: false,
            }
        },
        methods: {
            success(e) {
                this.modal = false
                this.sendSuccess = true
                setTimeout(() => {
                    this.sendSuccess = false
                }, 4000)
            },
        },
        computed: {
            user() {
                return this.$store.state.user.data;
            }
        },
        components: {
            AddDocument,
            DocumentRow,
            NotifyDocument,
            Toast,
        },
    }

</script>


<style scoped>
.text-info {
    font-size: 16px;
}
</style>
