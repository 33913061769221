<template>
    <div class="card my-3">

        <div class="card-header">
            <div :class="'d-flex flex-row justify-content-center p-0 btn handle ' + ((step.status === 'ongoing' && 'bg-warning') || (step.status === 'done' && 'bg-success') || 'bg-white')" style="border-top-left-radius: 3px; position: absolute; top: 0; left: 0; bottom: 0 ; width: 30px">
                <button v-if="user.role && (user.role.slug === 'admin' || user.role.slug === 'manager')" class="btn handle" >
                    <i class="fas fa-arrows-alt"></i>
                </button>
            </div>

            <div class="ml-4">
                <div class="row mb-3" v-if="editingstep">
                    <div class="col-4">
                        <label class="control-label small"><b>Catégorie :</b></label>
                        <v-select
                            taggable
                            class="pb-2"
                            @input="stepType = null"
                            v-model="stepCategory"
                            :options="categories"
                            :clearable="false"
                            :searchable="true"
                        ></v-select>
                    </div>
                    <div class="col-3">
                        <label class="control-label small"><b>Type :</b></label>
                        <v-select
                            taggable
                            class="pb-2"
                            v-model="stepType"
                            label="name"
                            :options="stepTypes"
                            :clearable="false"
                            :searchable="true"
                            @input="changed"
                        ></v-select>
                    </div>
                    <div class="col-2 pt-3">
                        <button class="btn btn-link btn-sm text-info mt-3" @click="savestep" :disabled="!step.step_type">
                            <i class="fas fa-save"></i> Enregistrer
                        </button>
                    </div>
                </div>
                <div v-else>
                    <h5 class="mb-0">
                        <button v-if="user.role && (user.role.slug === 'admin' || user.role.slug === 'manager')" class="btn btn-link btn-sm text-info float-left m-0 p-0 pr-2" @click="editstep">
                            <i class="fas fa-edit"></i>
                        </button>
                        <a href="#" class="toggle d-block mb-3 w-75" data-toggle="collapse" @click.prevent="show = !show">
                            <span>{{ step.step_type ? step.step_type.activity : '' }} - {{ step.step_type ? step.step_type.name : '' }}</span>
                        </a>
                    </h5>
                </div>

                <Header :step="step" :folder="folder" :i="i"></Header>
            </div>

        </div>
        <!-- END HEADER - BEGIN CONTENT -->
        <div :class="'collapse ' + (show ? 'show' : '')" aria-labelledby="headingOne" data-parent="#accordion">
            <div class="card-body p-0">
                <ul class="list-group list-group-flush" v-if="step.documents.length > 0">
                    <Document v-for="file in step.documents" :key="file.id" :file="file" :step="step" :folder="folder"></Document>
                </ul>
                <div class="text-center text-muted p-4" v-else>
                    Aucun document pour cette étape
                </div>
            </div>
        </div>

    </div>
    <!-- END CARD -->
</template>
<script>
    import axios from 'axios'
    import Header from './Header.vue'
    import Document from './Document.vue'



    export default {
        name: 'StepDetails',
        props: ['step', 'folder', 'i'],
        data() {
            return {
                show: false,
                modal: null,
                name: this.step.name,
                editingstep: false,
                stepCategory: this.step && this.step.step_type ? this.step.step_type.activity : null,
                stepType: this.step ? {...this.step.step_type} : null,
            }
        },
        methods: {
            changed() {
                if (typeof this.stepType === 'string') {
                    this.step.step_type = {
                        activity: this.stepCategory,
                        name: this.stepType,
                    }
                } else {
                    if (!this.stepType.activity) {
                        this.stepType.activity = this.stepCategory
                    }
                    this.step.step_type = this.stepType;
                }
            },
            editstep() {
                this.editingstep = true;
            },
            savestep() {
                this.step.name = this.step.step_type.name;
                delete this.step.step_type_id;
                if (this.step.step_type.id) {
                    this.step.step_type_id = this.step.step_type.id;
                }

                console.log('saving step', this.step)

                this.editingstep = false;

                axios.put(`/api/folders/${this.folder.id}/procedures/${this.folder.procedure.id}/steps/${this.step.id}`, this.step).then(res =>{
                    if (!this.folder.procedure) {
                        this.folder.procedure = {
                            steps: [],
                            name: "",
                        }
                    }
                    this.folder.procedure.steps.forEach(element => {
                        if (element.id === res.data.id){
                            element.partner_access = res.data.partner_access;
                            element.status = this.step.status;
                        }
                    })
                    // SET STEP TYPES
                    axios.get('/api/step_types')
                        .then(s => {
                            this.$store.commit('setStepTypes', s.data);
                        }).catch(err => {
                        console.log('could not get types', err)
                    })
                });
            }
        },
        computed: {
            user() {
                return this.$store.state.user.data
            },
            stepTypes() {
                if (this.stepCategory) {
                    return this.$store.state.stepTypes.items.filter(i => i.activity === this.stepCategory)
                }
                return this.$store.state.stepTypes.items
            },
            categories() {
                const ac = this.$store.state.stepTypes.items.map(i => i.activity);
                return [...new Set(ac)];
            },
        },
        components: {
            Header,
            Document,
        },
    }
</script>

<style scoped>
    .card-header {
        background: #f2f2f2;
    }
    a {
        color:black;
    }
</style>
