<template>
    <div class="modal-backdrop">
        <div class="modal" tabindex="-1" role="dialog" style="display: block">
            <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Conditions générales d'utilisation.</h5>
                        <button v-if="!user" type="button" class="close" v-on:click="$emit('close')" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body p-3">
                        <p>
                        Conditions générales d’utilisation « Espace Client Valoris »
                        </p>
                        <p>
                            Les présentes conditions générales d’utilisation réfèrent l’utilisation du site https://espaceclient.valoris.expert, nommé “Espace Client”. Cet espace appartient à la société Valoris Géomètre-Expert.
                        </p>
                        <p>
                        <h5>Article 1 : Définitions</h5>
                        <p>Client : tout professionnel ou personne physique capable au sens de l'Article 1145 du code civil, ou personne morale, qui visite l’espace client.</p>
                        <p>Partenaire : professionnel intervenant dans la vie du dossier, comme par exemple, avocat, notaire, architecte.</p>
                        <p>Utilisateur : Internaute se connectant à l’Espace Client.</p>
                        <p>Prestations et Services : l’espace client met à disposition des Clients différentes informations et documents liés à leur(s) dossier(s).</p>
                        <h5>Article 2 : Présentation</h5>
                        <p>
                            L’espace client Valoris Géomètre-Expert permet de consulter et de télécharger l’ensemble des documents liés à un dossier tels que les devis, factures et documents. D’autres fonctionnalités seront développées.
                        </p>
                            <h5>Article 3 : Accessibilité</h5>
                        <p>
                            Les utilisateurs (Clients et Partenaires) auront accès à l’ensemble des informations et des documents concernant leur(s) dossier(s) mis à disposition dans leur espace client.
                        </p>
                        <p>L'utilisateur est responsable de la bonne utilisation des éléments techniques de connexion. Ces derniers sont personnels et ne peuvent être divulgués.</p>
                        <p>Valoris Géomètre-Expert se réserve le droit de bloquer l’accès à l’Espace Client de façon temporaire ou définitive, sans aucune contrepartie.
                        </p>
                        <h5>Article 4 : Conditions générales</h5>
                        <p>
                            Lors de la première connexion de l’utilisateur, ce dernier doit cocher la case par laquelle il reconnaît avoir pris connaissance des présentes Conditions Générales. Valoris Géomètre-Expert se réserve le droit de modifier et de mettre à jour à tout moment l'accès à l’Espace Client ainsi que les présentes conditions d'utilisation. Ces modifications et mises à jour s'imposent à l'utilisateur qui doit en conséquence se référer régulièrement à cette rubrique pour vérifier les Conditions Générales d’utilisation en vigueur.
                        </p>
                                    <h5>Article 5 : Propriété intellectuelle</h5>
                        <p>
                            La structure générale de l’Espace client, ainsi que les textes, graphismes, logos, vidéos, icônes la composant, sont la propriété de Valoris Géomètre-Expert. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de Valoris Géomètre-Expert. Toute exploitation non autorisée du site ou de l’un quelconque des éléments qu’il contient sera considérée comme constitutive d’une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
                        </p>
                    </div>
                    <div class="modal-footer" v-if="user">
                        <button type="button" class="btn btn-primary" v-on:click="send">
                            <i class="fa fa-spinner fa-spin" v-if="saving"></i>
                            J'accepte les présentes Conditions
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from "axios";

    export default {
        props: ['user'],
        name: 'CGUModal',
        data() {
            return {
                saving : false,
                submitted : false,
            }
        },
        methods: {
            send () {
                this.saving = true;
                const u = {...this.user}
                delete u.company;
                delete u.role;
                axios.put(`/api/users/${this.user.id}`, {...u, cgu: true})
                .then(res => {
                    this.saving = false;
                    this.$store.commit('setUser', res.data)
                }).catch(e => {
                    alert('Impossible de sauvegarder votre choix, veuillez réessayer plus tard. ' + e.message)
                })
                this.$emit('close');
            },
        },
    }
</script>
