import axios from 'axios';

export default {
    state: { items: [], loading: false },
    mutations: {
        setContacts (state, items) {
            state.items = items;
        },
        addContact(state, item) {
            state.items = [...state.items, item];
        },
        setLoading(state, l) {
            state = {...state, loading: l}
        }
    },
    actions: {
        getContacts({ commit }) {
            commit('setLoading', true)
            const req = axios.get('/api/contacts')
            req.then(r =>  {
                commit('setContacts', r.data)
                commit('setLoading', false)
            }).catch(() => {
                commit('setLoading', false)
            })
            return req
        }
    }
};
