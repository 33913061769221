<template>
    <div class="stepwizard-step">
        <button ref="button" type="button"
                :class="'mx-1 btn btn-circle ' + ((step.status === 'ongoing' && 'btn-warning') || (step.status === 'done' && 'btn-success') || 'btn-default bg-white')"
                :title="`${step.name} : ${statusName}`"
                :content="`${step.name} : ${statusName}`"
                v-tippy="{ arrow:true,  animateFill: false, theme : 'light' }"
            >
        </button>
    </div>
</template>
<script>

    export default {
        name: 'StepButton',
        props: ['step'],
        computed: {
            statusName() {
                const st = this.$store.state.stepStatuses.items.find(s => s.slug === this.step.status)
                return st ? st.name : null
            }
        }
    }
</script>
