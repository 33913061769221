<template>
    <div class="card-body">
        <label class="control-label small"><b>Catégorie :</b></label>

        <v-select taggable class="pb-2" v-model="stepCategory" :options="categories" @input="stepType = null" :clearable="false" :searchable="true"></v-select>

        <label class="control-label small"><b>Type :</b></label>

        <v-select taggable class="pb-2" v-model="stepType" label="name" :options="stepTypes" :clearable="false" :searchable="true" @input="changed"></v-select>
    </div>
</template>
<script>

    export default {
        name : 'Step',
        props : ['step'],
        data() {
            return {
                stepCategory: this.step && this.step.step_type ? this.step.step_type.activity : null,
                stepType: this.step ? {...this.step.step_type} : null,
            }
        },

        computed: {
            stepTypes() {
                if (this.stepCategory) {
                    return this.$store.state.stepTypes.items.filter(i => i.activity === this.stepCategory)
                }
                return this.$store.state.stepTypes.items
            },
            categories() {
                const ac = this.$store.state.stepTypes.items.map(i => i.activity);
                return [...new Set(ac)];
            },
        },
        methods: {
            changed() {
                if (typeof this.stepType === 'string') {
                    this.step.step_type = {
                        activity: this.stepCategory,
                        name: this.stepType,
                    }
                } else {
                    if (!this.stepType.activity) {
                        this.stepType.activity = this.stepCategory
                    }
                    this.step.step_type = this.stepType;
                }
            }
        },
    }

</script>


<style scoped>

</style>
