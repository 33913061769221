<template>
    <li class="list-group-item"> <!-- TO DO :  v-for="" des documents de l'étape -->
        <div class="row">
            <div :class="user && user.role && (user.role.slug === 'admin' || user.role.slug === 'manager') ? 'col-md-6' : 'col-md-7'">
                {{ file.name }} <small class="text-muted" v-if="geoType">- <strong>{{dateString(file.date)}}</strong> - {{geoType.name}}</small><br/>
                <small>Mis en ligne le <strong>{{dateString(file.created_at)}}</strong> <span v-if="file.created_by">par <strong>{{file.created_by.firstname}} {{file.created_by.lastname}}</strong></span></small>
            </div>
            <div class="col-md-5 text-right">
                <a class="btn btn-outline-infox btn-sm p-0 small mr-3" @click="modal='download'">
                    <span class="small"><i class="fas fa-history"></i></span> <span class="ml-1 small">Historique téléchargement ({{file.downloads.length}})</span> <br/>
                </a>
                <!-- <small v-if="file.downloads && file.downloads.length > 0">
                    Téléchargé par&nbsp;:

                    <span v-for="dl in file.downloads">{{dl.user.firstname}} {{dl.user.lastname}} le {{dateString(dl.updated_at)}} </span>
                </small> -->

                <a :href="file.url" class="btn btn-outline-infox btn-sm p-0 mr-3" target="_blank"><span class="small"><i class="fas fa-file-download"></i></span> <span class="ml-1 small">Télécharger</span></a>
                <button class="btn  btn-outline-infox btn-sm p-0" @click.prevent="notify" v-if="user && user.role && (user.role.slug === 'admin' || user.role.slug === 'manager')">
                    <span class="small"><i class="fas fa-paper-plane"></i></span>
                    <span class="ml-1 small">Notifier</span>
                </button>
            </div>
            <div class="col-md-1 text-right" v-if="user && user.role && (user.role.slug === 'admin' || user.role.slug === 'manager')">
                <button class="btn btn-link text-danger" @click="removeDoc">
                    <i class="fas fa-spin fa-spinner" v-if="deleting"></i>
                    <i class="fas fa-trash" v-else></i>
                </button>
            </div>
        </div>
        <div class="row">
            <div :class="user && user.role && (user.role.slug === 'admin' || user.role.slug === 'manager') ? 'col-md-6' : 'col-md-7'">
            </div>
            <div class="col-md-6">
                <table class="table table-sm table-bordered table-striped text-sm">
                    <tbody>
                    <CommentRow v-for="comment in file.comments" :key="comment.id" :comment="comment" :file="file" :folder="folder" :step="step"></CommentRow>
                    </tbody>
                </table>

                <div v-if="user.role.slug === 'admin' || user.role.slug === 'manager'" class="btn  btn-link text-info btn-sm p-0 small w-100" @click="modal='comment'">
                    <i class="fas fa-comments"></i> <span class="ml-1 small">Ajouter un commentaire</span> <br/>
                </div>
            </div>

        </div>

        <Downloads  v-if="modal === 'download'" @close="modal=null" :file="file" :folder="folder" :step="step"></Downloads>
        <Comments  v-if="modal === 'comment'" @close="modal=null ; editingComment = null" :file="file" :folder="folder" :step="step" :comment="editingComment"></Comments>
        <NotifyDocument v-if="modal === 'notify'" :documents="[file]" @close="modal=null" :folder="folder" @success="success"></NotifyDocument>
        <Toast body="Votre message a bien été envoyé" title="Envoi réussi" v-if="sendSuccess"></Toast>
    </li>
</template>
<script>
    import axios from 'axios'

    import Comments from '../modals/Comments.vue';
    import Downloads from '../modals/Downloads.vue';
    import NotifyDocument from "../modals/NotifyDocument";
    import Toast from '../../../../utils/Toast'
    import CommentRow from './CommentRow'

    export default {
        name: "StepDocument",
        props: ['file', 'folder', 'step'],

        data() {
            return {
                modal : false,
                deleting: false,
                sendSuccess: false,
            }
        },
        methods: {

            dateString(date) {
                return (new Date(date)).toLocaleDateString('fr');
            },
            notify() {
                this.modal = 'notify'
            },
            success() {
                this.modal = false
                this.sendSuccess = true
                setTimeout(() => {
                    this.sendSuccess = false
                }, 4000)
            },
            removeDoc() {
                if (confirm(`Vous allez supprimer le document ${this.file.name}. Cette action est irréversible, mais le document sera conservé sur NextCloud. Souhaitez-vous continuer ?`)) {
                    this.deleting = true;
                    axios.delete(`/api/documents/${this.file.id}`).then(() => {
                        axios.get('/api/folders').then(r => {
                            this.$store.commit('setFolders', r.data)
                            this.deleting = false;
                        });
                    }).catch(e => {
                        alert('Suppression du document impossible (' + e.message + ')')
                        this.deleting = false;
                    })
                }
            }
        },
        computed: {
            geoType() {
                return this.$store.state.documentTypes.items.find(t => t.id === this.file.geo_document_type_id)
            },
            user() {
                return this.$store.state.user.data;
            }
        },

        components: {
            NotifyDocument,
            Toast,
            Comments,
            Downloads,
            CommentRow,
        }
    }
</script>
