<template>
    <div class="stepwizard col-md-offset-3">
        <div class="stepwizard-row setup-panel">
            <StepButton :step="step"  v-for="step in steps" :key="step.id"></StepButton>
        </div>
    </div>
</template>

<script>

    import StepButton from './StepButton.vue'

    export default {
        name : 'StepLine',
        props : ['steps'],
        data() {
            return {
            }
        },
        mounted(){

        },
        computed: {
            
        },
        components: {
            StepButton,
        },
    }

</script>
<style>
.stepwizard-row {
    display: table-row;
}
.stepwizard {
    display: table;
    /*width: 100%;*/
    position: relative;
    margin:0 auto;
}
/*
.stepwizard-row:before {
    top: 15px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: grey;
}
*/
/* .stepwizard-step:before {
    top: 15px;
    bottom: 0;
    position: absolute;
    content: " ";
    width: 100%;
    height: 1px;
    background-color: grey;
} */
.stepwizard-step:last-child::before {
    background-color: transparent!important;
}
.stepwizard-step {
    display: table-cell;
    text-align: center;
    position: relative;
}
.btn-circle {
    width: 25px !important;
    height: 25px !important;
    text-align: center !important;
    padding: 6px 0 !important;
    font-size: 12px !important;
    border-radius: 15px !important;
}
</style>